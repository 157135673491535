import { CharityStatus } from 'models/enums';

/**
 *  Charity model class.
 */
export default class Charity {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Name.
   */
  public readonly name: string;

  /**
   * Date of delete(iso).
   */
  public readonly deletedAt: Date | null;

  /**
   * Status.
   */
  public readonly status: CharityStatus;

  /**
   * Is fix price.
   */
  public readonly isFixPrice: boolean;

  /**
   * Fix price value.
   */
  public readonly fixPrice: number | null;

  /**
   * Is commented.
   */
  public readonly isCommented: boolean;

  /**
   * Comment title.
   */
  public readonly commentTitle: string | null;

  constructor(data: Charity) {
    this.id = data.id;
    this.name = data.name;
    this.deletedAt = data.deletedAt;
    this.status = data.status;
    this.fixPrice = data.fixPrice;
    this.isFixPrice = data.isFixPrice;
    this.isCommented = data.isCommented;
    this.commentTitle = data.commentTitle;
  }
}
