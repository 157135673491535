import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { UpdatePasswordForm } from 'features/user/components/UpdatePasswordForm';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import styles from './UpdatePasswordPage.module.css';

const UpdatePasswordPage = (): JSX.Element => {
  const params = useParams();

  return (
    <>
      <Helmet>
        <title>Update Password Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="My Profile Info" />
        <UpdatePasswordForm path={`/${params.slug}/sidebar/profile/edit`} />
      </div>
    </>
  );
};

export default UpdatePasswordPage;
