import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik, Form, FormikHelpers } from 'formik';
import { Divider, Grid } from '@material-ui/core';

import { BaseButton, BaseInputPassword } from 'components';
import { ERROR, SUCCESS } from 'components/AppSnackbar';
import { getValidationErrors } from 'utils/transformErrors';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { updatePassword } from 'store/Auth';

import ApiError from 'models/ApiError';
import { formValidation, initialFormState, UpdatePassword } from './formSettings';

import styles from './UpdatePasswordForm.module.css';

interface UpdatePasswordFormProps {
  /**
   * Data for route if UpdatePassword pop up closed.
   */
  path: string;
}

const UpdatePasswordFormComponent = ({
  path,
}: UpdatePasswordFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { loading } = useAppSelector((state) => state.auth);

  /**
   * Method for closing UpdatePassword pop up .
   */
  const handleCancelUpdatePassword = () => {
    navigate(`${path}`);
  };

  const handleSubmit = async (
    values: UpdatePassword,
    { setErrors }: FormikHelpers<UpdatePassword>,
  ) => {
    try {
      await dispatch(updatePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword })).unwrap();

      snackbar.enqueueSnackbar('Your password has been changed successfully', SUCCESS);
      navigate(`${path}`);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.errors) {
        setErrors(getValidationErrors(apiError.errors));
      } else {
        snackbar.enqueueSnackbar(apiError.message, ERROR);
      }
    }
  };

  return (
    <Formik
      initialValues={{ ...initialFormState }}
      onSubmit={handleSubmit}
      validationSchema={formValidation}
      validateOnBlur={false}
    >
      <Form noValidate className={styles.form}>
        <Grid container spacing={1} className={styles.controlsWrapper}>
          <Grid item xs={12}>
            <BaseInputPassword
              id="oldPassword"
              label="Old Password"
              name="oldPassword"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInputPassword
              id="newPassword"
              label="New Password"
              name="newPassword"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInputPassword
              id="newPasswordConfirmation"
              label="Confirm Password"
              name="newPasswordConfirmation"
              required
            />
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          <Divider />
          <Grid container spacing={2} className={styles.buttons}>
            <Grid item xs={6}>
              <BaseButton
                size="large"
                variant="outlined"
                onClick={handleCancelUpdatePassword}
                disableElevation
                fullWidth
              >
                Cancel
              </BaseButton>
            </Grid>
            <Grid item xs={6}>
              <BaseButton
                size="large"
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
                fullWidth
                loading={loading}
              >
                Save
              </BaseButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
};

export const UpdatePasswordForm = memo(UpdatePasswordFormComponent);
