import React from 'react';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import { getFormattedDate, SLASH_FORMAT } from 'utils/date';
import { getFormattedCurrency } from 'utils/currency';
import { Box, Tooltip } from '@material-ui/core';
import { RefreshIcon } from 'components/Icons/RefreshIcon';

const columns: GridColumns = [
  {
    field: 'createdAt',
    headerName: 'Date',
    width: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }: GridCellParams) => {
      if (value) {
        return getFormattedDate(value as Date, SLASH_FORMAT);
      }

      return value?.toString();
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: ({ value }: GridCellParams) => {
      if (value) {
        return (
          <strong>{getFormattedCurrency(Number(value))}</strong>
        );
      }

      return value;
    },
  },
  {
    field: 'name',
    headerName: 'Donated to',
    flex: 0.65,
    sortable: false,
  },
  {
    field: 'isReoccurring',
    headerName: ' ',
    width: 40,
    minWidth: 40,
    sortable: false,
    renderCell: ({ value }: GridCellParams) => {
      if (value) {
        return (
          <Tooltip title="Recurring Payment">
            <Box display="flex" alignItems="center">
              <RefreshIcon />
            </Box>
          </Tooltip>
        );
      }

      return value;
    },
  },
];

export { columns };
