import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from 'notistack';

import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import {
  AppConfirmationModal, ERROR, Loading, SUCCESS,
} from 'components';
import {
  deleteReoccurringPaymentById,
  getReoccurringPaymentById,
  getReoccurringPayments,
} from 'store/UserPayment/dispatchers';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import { ReoccurringForm } from 'features/user/components/ReoccurringForm';
import styles from './ReoccurringEditPage.module.css';

const ReoccurringEditPage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { details, error, removing } = useAppSelector((state) => state.userPayment.reoccurring);

  const [isOpenStopReoccurring, setOpenStopReoccurring] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch(getReoccurringPaymentById(Number(params.id)));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (error) {
      snackbar.enqueueSnackbar(error, ERROR);
    }
  }, [error, snackbar]);

  /**
   * It's opening and closing StopReoccurring pop up.
   */
  const updateStopReoccurringModal = useCallback((isOpen: boolean) => (): void => {
    setOpenStopReoccurring(isOpen);
  }, []);

  const reoccurringId = Number(params.id) ?? null;
  const pathReoccurringInfo = `/${params.slug}/sidebar/reoccurring`;
  const reoccurringForm = (
    <ReoccurringForm
      path={pathReoccurringInfo}
      reoccurringId={reoccurringId}
      onOpenStopReoccurring={updateStopReoccurringModal(true)}
    />
  );

  /**
   * It's stopping recurring payment.
   */
  const handleStopReoccurring = useCallback(async (): Promise<void> => {
    updateStopReoccurringModal(false);

    await dispatch(deleteReoccurringPaymentById(reoccurringId)).unwrap();
    snackbar.enqueueSnackbar('This recurring payment has been stopped successfully', SUCCESS);

    dispatch(getReoccurringPayments());
    navigate(pathReoccurringInfo);
  }, [dispatch, navigate, pathReoccurringInfo, reoccurringId, snackbar, updateStopReoccurringModal]);

  if (error && !details) {
    return <Navigate to={pathReoccurringInfo} replace />;
  }

  return (
    <>
      <Helmet>
        <title>Recurring Edit Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="Edit Recurring Payments" />
        {!details ? <Loading /> : reoccurringForm}
      </div>
      <AppConfirmationModal
        header="Are you sure?"
        buttonText="Stop Recurring Payment"
        message={`Are you sure you want to stop this recurring payment?
          This action cannot be undone.`}
        processing={removing}
        open={isOpenStopReoccurring}
        onConfirm={handleStopReoccurring}
        onCloseModal={updateStopReoccurringModal(false)}
      />
    </>
  );
};

export default ReoccurringEditPage;
