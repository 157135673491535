import React, { memo } from 'react';
import {
  Box, Grid, ListItem, Typography,
} from '@material-ui/core';

import ReoccurringPayment from 'models/ReoccurringPayment';
import { getFormattedCurrency } from 'utils/currency';
import { BaseButtonLink } from 'components';
import { DATE_PICKER_FORMAT, getFormattedDate } from 'utils/date';
import styles from './UserReoccurringPayment.module.css';

interface UserReoccurringPaymentProps {
  /**
   * Data for route after go to the Link.
   */
  path: string;
  /**
   * User recurring payment item.
   */
  payment: ReoccurringPayment;
}

const UserReoccurringPaymentComponent = ({
  path, payment,
}: UserReoccurringPaymentProps): JSX.Element => (
  <ListItem className={styles.itemWrapper}>
    <Box display="flex">
      <Grid container>
        <Grid item xs={4} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.namePayment}
            paragraph
          >
            Donated to
          </Typography>
        </Grid>
        <Grid item xs={8} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.infoPayment}
            title={payment?.donatedTo}
            noWrap
            paragraph
          >
            {payment?.donatedTo}
          </Typography>
        </Grid>
        <Grid item xs={4} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.namePayment}
            paragraph
          >
            Amount
          </Typography>
        </Grid>
        <Grid item xs={8} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.infoPayment}
            paragraph
          >
            {getFormattedCurrency(payment?.amount)}
          </Typography>
        </Grid>
        <Grid item xs={4} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.namePayment}
            paragraph
          >
            Frequency
          </Typography>
        </Grid>
        <Grid item xs={8} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.infoPayment}
            paragraph
          >
            {payment.paymentFrequency?.name}
          </Typography>
        </Grid>
        <Grid item xs={4} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.namePayment}
            paragraph
          >
            Charged on
          </Typography>
        </Grid>
        <Grid item xs={8} className={styles.dataWrapper}>
          <Typography
            variant="body1"
            className={styles.infoPayment}
            paragraph
          >
            {getFormattedDate(payment?.chargeAt, DATE_PICKER_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
      <div className={styles.actionWrapper}>
        <BaseButtonLink variant="outlined" to={path} disableElevation>
          Edit
        </BaseButtonLink>
      </div>
    </Box>
  </ListItem>
);

export const UserReoccurringPayment = memo(UserReoccurringPaymentComponent);
