import UserTransaction from 'models/UserTransaction';
import UserTransactionDto from 'api/dtos/userTransactionDto';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';

/**
 * Map DTO to User Transaction model.
 */
export const mapDtoToUserTransaction = (
  dto: UserTransactionDto,
): UserTransaction => new UserTransaction({
  id: dto.id,
  amount: Number(dto.amount),
  name: dto.name,
  createdAt: new Date(dto.created_at),
  isReoccurring: dto.is_reoccurring,
});

/**
 * Map DTO to User Transactions paged list.
 */
export function mapDtoToUserTransactions(
  dtos: ResponseDto<UserTransactionDto>,
): PagedList<UserTransaction> {
  return new PagedList<UserTransaction>({
    results: dtos.results?.map(mapDtoToUserTransaction),
  });
}
