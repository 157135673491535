import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider, List, ListItem, ListItemText,
} from '@material-ui/core';

import { ProfileMenuType } from 'models/enums/ProfileMenuType';
import styles from './UserProfileMenu.module.css';

interface UserProfileMenuProps {
  /**
   * Data for route after choose item menu.
   */
  path: string;
}

const UserProfileMenuComponent = ({
  path,
}: UserProfileMenuProps): JSX.Element => {
  const navigate = useNavigate();

  /**
   * Method for navigating to item menu.
   */
  const handleNavigate = (item: ProfileMenuType) => (): void => {
    navigate(`${path}/${item}`);
  };

  return (
    <List disablePadding>
      <ListItem
        button
        className={styles.navItem}
        onClick={handleNavigate(ProfileMenuType.Payment)}
      >
        <ListItemText primary="Add/Change Payment" className={styles.link} />
      </ListItem>
      <Divider />
      <ListItem
        button
        className={styles.navItem}
        onClick={handleNavigate(ProfileMenuType.Transactions)}
      >
        <ListItemText primary="Transactions History" className={styles.link} />
      </ListItem>
      <Divider />
      <ListItem
        button
        className={styles.navItem}
        onClick={handleNavigate(ProfileMenuType.Reoccurring)}
      >
        <ListItemText primary="Manage Recurring Payments" className={styles.link} />
      </ListItem>
      <Divider />
      <ListItem
        button
        className={styles.navItem}
        onClick={handleNavigate(ProfileMenuType.Profile)}
      >
        <ListItemText primary="My Profile Info" className={styles.link} />
      </ListItem>
      <Divider />
    </List>
  );
};

export const UserProfileMenu = memo(UserProfileMenuComponent);
