import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar, Chip, ListItem, ListItemText,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import UserPayment from 'models/UserPayment';
import { getLast4 } from 'utils/getLast4';

import styles from './UserPaymentItem.module.css';

interface UserPaymentItemProps {
  /**
   * Data for route after go to the Link.
   */
  path: string;
  /**
   * User payment item.
   */
  payment: UserPayment;
}

const UserPaymentItemComponent = ({
  path, payment,
}: UserPaymentItemProps): JSX.Element => (
  <ListItem component={Link} to={path} className={styles.button} button>
    <ListItemText className={styles.content}>
      <span className={styles.nameData} title={payment.name ?? 'name for this payment method'}>{payment.name}</span>
      <span className={styles.last4Data}>{getLast4(payment.last4)}</span>
      {payment.isDefaultPayment
          && (
          <Chip
            avatar={<Avatar className={styles.avatar}><CheckIcon fontSize="small" /></Avatar>}
            className={styles.chip}
            label="Default"
            size="small"
          />
          )}
    </ListItemText>
    <ChevronRightIcon />
  </ListItem>
);

export const UserPaymentItem = memo(UserPaymentItemComponent);
