import Charity from 'models/Charity';
import Banner from 'models/Banner';
import BrandingColor from 'models/BrandingColor';

/**
 *  Organization model class.
 */
export default class Organization {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * Organization name.
   */
  public readonly name: string;

  /**
   * Organization address.
   */
  public readonly address: string;

  /**
   * City.
   */
  public readonly city: string;

  /**
   * State.
   */
  public readonly state: string;

  /**
   * ZIP code.
   */
  public readonly zip: string;

  /**
   * Fee.
   */
  public readonly fee: number;

  /**
   * Fee amount.
   */
  public readonly feeAmount: number;

  /**
   * Charities.
   */
  public readonly charities: Charity[];

  /**
   * Banner url.
   */
  public readonly banner: Banner | null;

  /**
   * Branding color.
   */
  public readonly brandingColor: BrandingColor | null;

  /**
   * Logo url.
   */
  public readonly logoUrl: string | null;

  /**
   * Show logo.
   */
  public readonly showLogo: boolean;

  /**
   * Show donation.
   */
  public readonly showDonation: boolean;

  /**
   * Show fee.
   */
  public readonly showFee: boolean;

  /**
   * Show banner.
   */
  public readonly showBanner: boolean;

  /**
   * ACH merchant id.
   */
  public readonly achMerchantId: string | null;

  /**
   * Tackon bearer token.
   */
  public readonly tackonBearerToken: string | null;

  /**
   * Tackon phone.
   */
  public readonly tackonPhone: string | null;

  constructor(data: OrganizationConstructorArgs) {
    this.id = data.id;
    this.name = data.name;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.fee = data.fee;
    this.feeAmount = data.feeAmount;
    this.charities = data.charities;
    this.banner = data.banner;
    this.brandingColor = data.brandingColor;
    this.logoUrl = data.logoUrl;
    this.showLogo = data.showLogo;
    this.showDonation = data.showDonation;
    this.showFee = data.showFee;
    this.showBanner = data.showBanner;
    this.achMerchantId = data.achMerchantId;
    this.tackonBearerToken = data.tackonBearerToken;
    this.tackonPhone = data.tackonPhone;
  }

  /**
   * Organization full address.
   */
  public get fullAddress(): string {
    return `${this.address}., ${this.city}, ${this.state} ${this.zip}`.trim();
  }
}

interface OrganizationConstructorArgs {
  id: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  fee: number;
  feeAmount: number;
  charities: Charity[];
  banner: Banner | null;
  brandingColor: BrandingColor | null;
  logoUrl: string | null;
  showLogo: boolean;
  showDonation: boolean;
  showFee: boolean;
  showBanner: boolean;
  achMerchantId: string | null;
  tackonBearerToken: string | null;
  tackonPhone: string | null;
}
