import { camelize } from 'humps';
import { ApiErrorDetails } from 'api/dtos/apiErrorDto';

/**
 * Transform server-side errors from snake_case to camelCase for displaying validation fields.
 */
export const getValidationErrors = (validationErrors: ApiErrorDetails | undefined): object => {
  if (!validationErrors) return {};

  return Object.keys(validationErrors).reduce((acc, key) => ({
    ...acc,
    // TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type.
    // @ts-ignore
    [camelize(key)]: validationErrors[key],
  }),
  {});
};
