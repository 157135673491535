import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { TransactionsHistory } from 'features/user/components/TransactionsHistory';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';

import styles from './TransactionsInfoPage.module.css';

const TransactionsInfoPage = (): JSX.Element => {
  const params = useParams();

  return (
    <>
      <Helmet>
        <title>Transactions Info Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard
          path={`/${params.slug}`}
          title="Transactions History"
          variant="outlined"
          isBackToMenu
        />
        <TransactionsHistory />
      </div>
    </>
  );
};

export default TransactionsInfoPage;
