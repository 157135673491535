import {
  format, parseISO, isValid, getDay,
} from 'date-fns';
import { DayOfWeek } from 'models/enums';

export const DATE_PICKER_FORMAT = 'MMM d, yyyy';
export const SHORT_FORMAT = 'yyyy-MM-dd';
export const SLASH_FORMAT = 'MM/dd/yyyy';

/**
 * Convert date. If the date is invalid will return an empty string.
 * @example Default format: July 12, 2021
 */
export const getFormattedDate = (
  date: Date | string | null | undefined,
  dateFormat: string = DATE_PICKER_FORMAT,
): string => {
  if (typeof date === 'string') {
    return format(parseISO(date), dateFormat);
  }

  if (!isValid(date) || !date) {
    return '';
  }

  return format(date, dateFormat);
};

/**
 * Get day of week of given date. Returns type number.
 */
export const getDayOfWeek = (
  date: Date | string,
): number => {
  if (typeof date === 'string') {
    return getDay(parseISO(date));
  }

  return getDay(date);
};

/**
 * Days of week.
 */
export const DAYS = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
];
