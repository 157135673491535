import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchUserPayments,
  fetchUserPaymentById,
  createUserPayment as createPayment,
  updateUserPayment as updatePayment,
  deleteUserPayment as deletePayment,
  fetchReoccurringPayments,
  fetchReoccurringPaymentById,
  deleteReoccurringPayment as deleteReoccurring,
  updateReoccurringPayment as updateReoccurring,
} from 'api/services/UserPayment';
import transformAPIErrors from 'utils/transformAPIErrors';
import UserSavePayment, { UserSavePaymentControl } from 'models/UserSavePayment';
import ApiError from 'models/ApiError';
import ReoccurringPayment from 'models/ReoccurringPayment';
import { fetchPaymentToken } from 'api/services/Organization';
import PaymentToken from 'models/PaymentToken';
import { PaymentMethod } from 'models/enums';

/**
 * Get user payments method.
 */
export const getUserPayments = createAsyncThunk(
  'userPayment/getUserPayments',
  () => fetchUserPayments(),
);

/**
 * Create user payment method.
 */
export const createUserPayment = createAsyncThunk<void, { payment: UserSavePaymentControl }, {
  rejectValue: ApiError;
}>(
  'userPayment/createUserPayment',
  async (data, { rejectWithValue }) => {
    try {
      if (data.payment.paymentMethod === PaymentMethod.Ach) {
        const { token } = await fetchPaymentToken({ account: data.payment.token });

        const payment: UserSavePayment = {
          ...data.payment,
          last4: token.slice(-4),
          token,
        };

        return await createPayment(payment);
      }

      const payment: UserSavePayment = {
        ...data.payment,
        last4: data.payment.token.toString().slice(-4),
      };

      return await createPayment(payment);
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);
      return rejectWithValue(serverErrors);
    }
  },
);

/**
 * Get user payment method.
 */
export const getUserPaymentById = createAsyncThunk(
  'userPayment/getUserPayment',
  (id: number) => fetchUserPaymentById(id),
);

/**
 * Update user payment method.
 */
export const updateUserPaymentById = createAsyncThunk<void, { payment: UserSavePaymentControl, id: number }, {
  rejectValue: ApiError;
}>(
  'user/updateUserPayment',
  async (data, { rejectWithValue }) => {
    try {
      const payment: UserSavePayment = {
        ...data.payment,
        last4: data.payment.token.toString().slice(-4),
      };

      return await updatePayment(payment, data.id);
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);
      return rejectWithValue(serverErrors);
    }
  },
);

/**
 * Delete user payment method.
 */
export const deleteUserPaymentById = createAsyncThunk(
  'user/deleteUserPayment',
  (id: number) => deletePayment(id),
);

/**
 * Reset user payment details method.
 */
export const resetUserPayment = createAction('user/resetUserPayment');

/**
 * Get user recurring payments method.
 */
export const getReoccurringPayments = createAsyncThunk(
  'userPayment/getReoccurringPayments',
  () => fetchReoccurringPayments(),
);

/**
 * Get user recurring payment method.
 */
export const getReoccurringPaymentById = createAsyncThunk(
  'userPayment/getReoccurringPayment',
  (id: number) => fetchReoccurringPaymentById(id),
);

/**
 * Delete recurring payment method.
 */
export const deleteReoccurringPaymentById = createAsyncThunk(
  'user/deleteReoccurringPayment',
  (id: number) => deleteReoccurring(id),
);

/**
 * Reset recurring payment details method.
 */
export const resetReoccurringPayment = createAction('user/resetReoccurringPayment');

/**
 * Update recurring payment method.
 */
export const updateReoccurringPaymentById = createAsyncThunk<void, {
  payment: ReoccurringPayment,
  paymentToken: PaymentToken,
  id: number }, {
  rejectValue: ApiError;
}>(
  'user/updateReoccurringPayment',
  async (data, { rejectWithValue }) => {
    try {
      if (!data.payment.userPaymentId && data.payment.paymentMethod === PaymentMethod.Ach) {
        const { token } = await fetchPaymentToken(data.paymentToken);

        const payment: ReoccurringPayment = {
          ...data.payment,
          last4: token.slice(-4),
          token,
        };

        return await updateReoccurring(payment, data.id);
      }

      return await updateReoccurring(data.payment, data.id);
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      return rejectWithValue(serverErrors);
    }
  },
);
