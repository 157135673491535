import React, { memo } from 'react';
import { useSnackbar } from 'notistack';
import {
  Formik, Form, FormikHelpers,
} from 'formik';
import { Grid } from '@material-ui/core';

import { BaseButton, BaseInput, BaseInputPassword } from 'components';
import { ERROR } from 'components/AppSnackbar';
import { getValidationErrors } from 'utils/transformErrors';
import transformAPIErrors from 'utils/transformAPIErrors';
import { ServerValidationError } from 'utils/exceptions';
import { getNumberFormat } from 'utils/phone';
import { create } from 'store/User';
import { useAppSelector, useAppDispatch } from 'store/rootReducer';
import Register from 'models/Register';

import { formValidation, initialFormState } from './formSettings';
import styles from './SignUpForm.module.css';

interface SignUpFormProps {
  /**
   * Callback on success.
   */
  onSuccess: () => void;
}

const SignUpFormComponent = ({ onSuccess }: SignUpFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const { processing } = useAppSelector((state) => state.user);
  const { data } = useAppSelector((state) => state.organization);

  const handleSubmit = async (values: Register, { setErrors }: FormikHelpers<Register>) => {
    try {
      await dispatch(create({ ...values, phone: getNumberFormat(values.phone) })).unwrap();

      // Close SignUp modal.
      onSuccess();
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      if (error instanceof ServerValidationError) {
        setErrors(getValidationErrors(serverErrors.errors));
      } else {
        snackbar.enqueueSnackbar(serverErrors.message, ERROR);
      }
    }
  };

  const formInitValues = initialFormState(data?.id ?? 0);

  return (
    <Formik
      initialValues={formInitValues}
      onSubmit={handleSubmit}
      validationSchema={formValidation}
      validateOnBlur={false}
    >
      <Form noValidate className="app-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <BaseInput
              label="First Name"
              id="firstName"
              name="firstName"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BaseInput
              label="Last Name"
              id="lastName"
              name="lastName"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInput
              label="Email"
              id="email"
              name="email"
              type="email"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BaseInput
              label="Cell #"
              id="phone"
              name="phone"
              type="tel"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInputPassword
              id="password"
              label="Password"
              name="password"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseButton
              size="large"
              color="primary"
              type="submit"
              variant="contained"
              disableElevation
              fullWidth
              loading={processing}
              className={styles.button}
            >
              Sign Up
            </BaseButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export const SignUpForm = memo(SignUpFormComponent);
