import { ApiErrorDetails, ApiErrorDto } from 'api/dtos/apiErrorDto';
import ApiError from 'models/ApiError';

/**
 * Transform server-side error to client-side(needs for validation).
 */
export default function transformAPIErrors(data: ApiErrorDto): ApiError {
  // If error exist preparing detailed errors for displaying for input field.
  if (data.errors?.length) {
    const listErrors = data.errors && data.errors.map((error: ApiErrorDetails) => ({
      [error.field]: error.messages.join('\n'),
    }));

    if (listErrors) {
      const errors = Object.assign({}, ...listErrors.map((item: Record<string, string>) => item));

      return new ApiError({
        errors,
        message: data.message,
      });
    }
  }

  return new ApiError({
    message: data.message,
  });
}
