import { addRequestInterceptor, addResponseInterceptor } from 'api';
import { addTokenBeforeRequest, afterFailedRequest } from 'api/interceptors';

const KEY = '-=SESSION_TOKEN_APP_LANDING=-';

let sessionToken: string | null = localStorage.getItem(KEY) || sessionStorage.getItem(KEY) || null;

/**
 * Save session token into local storage.
 */
const setLocalStorage = (token?: string) => {
  if (!token) {
    localStorage.removeItem(KEY);
  } else {
    localStorage.setItem(KEY, token);
  }
};

/**
 * Save session token into session storage.
 */
const setSessionStorage = (token?: string) => {
  if (!token) {
    sessionStorage.removeItem(KEY);
  } else {
    sessionStorage.setItem(KEY, token);
  }
};

/**
 * Sets session token.
 */
export const setSessionToken = (token?: string, rememberMe: boolean = true) => {
  if (rememberMe) {
    setLocalStorage(token);
  } else {
    setSessionStorage(token);
  }

  sessionToken = token || null;
};

/**
 * Check if a token is saved for the next session.
 */
export const isRememberMe = (): boolean => Boolean(localStorage.getItem(KEY));

/**
 * Gets session token.
 */
export const getSessionToken = () => sessionToken;

addRequestInterceptor(addTokenBeforeRequest);

addResponseInterceptor((v) => v, afterFailedRequest);
