/**
 *  Payment token model class.
 */
// eslint-disable-next-line max-classes-per-file
export default class PaymentToken {
  /**
   * Account number.
   */
  public readonly account?: string;

  constructor(data: PaymentToken) {
    this.account = data.account;
  }
}

/**
 *  Token model class.
 */
export class PaymentTokenResponse {
  /**
   * Cardpointe token.
   */
  public readonly token: string;

  constructor(data: PaymentTokenResponse) {
    this.token = data.token;
  }
}
