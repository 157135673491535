import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@material-ui/core';

import { BaseButtonLink } from 'components';
import { useAppSelector } from 'store/rootReducer';
import { getInternationalFormat } from 'utils/phone';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import styles from './ProfileInfoPage.module.css';

const ProfileInfoPage = (): JSX.Element => {
  const params = useParams();
  const location = useLocation();

  const { user } = useAppSelector((state) => state.user);

  return (
    <>
      <Helmet>
        <title>Profile Info Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="My Profile Info" isBackToMenu />
        <Grid container className={styles.content}>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              paragraph
              className={styles.nameProfile}
            >
              Name
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              paragraph
              className={styles.infoProfile}
            >
              {user?.fullName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              paragraph
              className={styles.nameProfile}
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              paragraph
              className={styles.infoProfile}
            >
              {user?.email}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              paragraph
              className={styles.nameProfile}
            >
              Cell #
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="body1"
              paragraph
              className={styles.infoProfile}
            >
              {getInternationalFormat(user?.phone)}
            </Typography>
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          <BaseButtonLink
            size="large"
            variant="outlined"
            disableElevation
            fullWidth
            className={styles.button}
            to={`${location.pathname}/edit`}
          >
            Edit
          </BaseButtonLink>
        </div>
      </div>
    </>
  );
};

export default ProfileInfoPage;
