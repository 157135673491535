import { PaymentMethod } from 'models/enums';

/**
 *  User payment model class.
 */
export default class UserPayment {
  /**
   * Unique Id.
   */
  public readonly id?: number;

  /**
   * Payment method.
   */
  public readonly paymentMethod: PaymentMethod;

  /**
   * Last 4 numbers of a card number(account).
   */
  public readonly last4: string;

  /**
   * Name.
   */
  public readonly name: string | null;

  /**
   * Card holder name.
   */
  public readonly cardName?: string | null;

  /**
   * ACH name.
   */
  public readonly achName?: string | null;

  /**
   * Is default payment.
   */
  public readonly isDefaultPayment: boolean;

  /**
   * User's id.
   */
  public readonly userId: number;

  /**
   * Receipt recipient.
   */
  public readonly receipt: string;

  /**
   * Donate as.
   */
  public readonly donateAs: string | null;

  /**
   * Cardpointe token.
   */
  public readonly token: string;

  /**
   * Cardpointe expiry.
   */
  public readonly expiry: string;

  /**
   * ZIP.
   */
  public readonly zip?: string | null;

  constructor(data: UserPayment) {
    this.id = data.id;
    this.paymentMethod = data.paymentMethod;
    this.last4 = data.last4;
    this.name = data.name;
    this.cardName = data.cardName;
    this.achName = data.achName;
    this.isDefaultPayment = data.isDefaultPayment;
    this.userId = data.userId;
    this.receipt = data.receipt;
    this.donateAs = data.donateAs;
    this.token = data.token;
    this.expiry = data.expiry;
    this.zip = data.zip;
  }
}
