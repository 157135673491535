import React, { ReactNode, memo } from 'react';
import { Box, Grid } from '@material-ui/core';

import logo from 'assets/images/big-logo.svg';
import styles from 'layout/AuthLayout/AuthLayout.module.css';

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayoutComponent = ({ children }: AuthLayoutProps): JSX.Element => (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" className={styles.logo}>
          <img src={logo} alt="Generosity" />
        </Box>
      </Grid>
    </Grid>
    {children}
  </>
);

export const AuthLayout = memo(AuthLayoutComponent);
