import React, { ChangeEvent, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@material-ui/core';

import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import { PaymentCreditForm } from 'features/user/components/PaymentCreditForm';
import { PaymentAchForm } from 'features/user/components/PaymentAchForm';
import { PaymentMethod } from 'models/enums';

import styles from './PaymentAddPage.module.css';

const PaymentAddPage = (): JSX.Element => {
  const params = useParams();
  const [
    paymentMethod,
    setPaymentMethod,
  ] = useState<PaymentMethod>(PaymentMethod.Card);

  const pathPaymentInfo = `/${params.slug}/sidebar/payment`;

  const handlePaymentMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setPaymentMethod(value as PaymentMethod);
  };

  const getPaymentMethodControls = (): ReactNode => {
    switch (paymentMethod) {
      case PaymentMethod.Card: {
        return <PaymentCreditForm path={pathPaymentInfo} />;
      }

      case PaymentMethod.Ach: {
        return <PaymentAchForm path={pathPaymentInfo} />;
      }

      default: {
        return null;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Payment Add Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="Add Payment Method" />

        <Grid container spacing={2} className={styles.controlsWrapper}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="payment method"
                className={styles.radioGroup}
                name="paymentMethod"
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
              >
                <FormControlLabel
                  value={PaymentMethod.Card}
                  control={<Radio />}
                  label={<Typography variant="body2">New Debit/Credit</Typography>}
                />
                <FormControlLabel
                  value={PaymentMethod.Ach}
                  control={<Radio />}
                  label={<Typography variant="body2">New ACH</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {getPaymentMethodControls()}
      </div>
    </>
  );
};

export default PaymentAddPage;
