import Auth, { ConfirmResetPassword, Token } from 'models/Auth';
import {
  AuthDto, LoginDto, ConfirmResetPasswordDto,
} from 'api/dtos/authDto';

/**
 * Map Auth model to DTO.
 */
export const mapLoginToDto = (payload: Auth): LoginDto => ({
  email: payload.email,
  password: payload.password,
});

/**
 * Map DTO to Token model.
 */
export const mapDtoToLogin = (payload: AuthDto): Token => ({
  token: payload.token,
});

/**
 * Map Confirm Reset Password model to DTO.
 */
export const mapConfirmResetPasswordToDto = (payload: ConfirmResetPassword): ConfirmResetPasswordDto => ({
  email: payload.email,
  token: payload.token,
  password: payload.password,
  password_confirmation: payload.passwordConfirmation,
});
