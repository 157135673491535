import React, { memo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, AppBar, Toolbar } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import { removeTrailingSlash } from 'utils/removeTrailingSlash';
import { useAppSelector } from 'store/rootReducer';
import { BaseButton } from 'components';
import { SignUpModal } from 'features/user/components/SignUpModal';
import { LoginModal } from 'features/auth/components/LoginModal';

import styles from './AppHeader.module.css';

interface AppHeaderProps {
  /**
   * User name.
   */
  userName: string | null;
  /**
   * Check if user is on page with slug organization.
   */
  isOrganization: boolean;
}

const AppHeaderComponent = ({ userName, isOrganization }: AppHeaderProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isDisabledControls = Boolean(searchParams.get('preview'));
  const hasRegistryTrue = Boolean(!userName && (searchParams.get('register') === 'true'));

  const { loading } = useAppSelector((state) => state.user);

  const [isOpenSignUp, setOpenSignUp] = useState(hasRegistryTrue || false);
  const [isOpenLogin, setOpenLogin] = useState(false);

  /**
   * It's clearing 'register' query params.
   */
  const clearRegistryParams = (): void => {
    searchParams.delete('register');
    setSearchParams(searchParams);
  };

  /**
   * It's opening and closing SignUp pop up.
   */
  const updateSignUpModalState = (isOpen: boolean) => (): void => {
    if (hasRegistryTrue) {
      clearRegistryParams();
    }
    setOpenSignUp(isOpen);
  };

  /**
   * It's opening and closing Login pop up.
   */
  const updateLoginModalState = (isOpen: boolean) => (): void => {
    setOpenLogin(isOpen);
  };

  /**
   * It's closes an opened pop up and opens a closed: SignUp or Login.
   */
  const handleChangeModal = (isOpenSignUp: boolean, isOpenLogin: boolean) => (): void => {
    if (hasRegistryTrue) {
      clearRegistryParams();
    }
    setOpenSignUp(isOpenSignUp);
    setOpenLogin(isOpenLogin);
  };

  /**
   * It's opening Profile pop up.
   */
  const handleOpenSidebar = (): void => {
    const trailedUrl = removeTrailingSlash(location.pathname);

    navigate(`${trailedUrl}/sidebar`);
  };

  let headerMenu = userName
    ? (
      <BaseButton
        color="default"
        variant="contained"
        className={styles.profileButton}
        onClick={handleOpenSidebar}
        endIcon={<ArrowDropDown />}
        disableElevation
      >
        <Typography variant="subtitle2" className={styles.userTitle} color="inherit">
          {userName}
        </Typography>
      </BaseButton>
    )
    : (
      <>
        <BaseButton
          color="default"
          variant="contained"
          className={styles.button}
          onClick={updateLoginModalState(true)}
          disableElevation
        >
          Login
        </BaseButton>
        <BaseButton
          color="default"
          variant="contained"
          className={styles.button}
          onClick={updateSignUpModalState(true)}
          disableElevation
        >
          Sign Up
        </BaseButton>
      </>
    );

  if (isDisabledControls) {
    headerMenu = <></>;
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={styles.host}
        elevation={0}
      >
        {!loading
          && (
          <Toolbar>
            <Typography variant="subtitle2" className={styles.pageTitle} color="inherit">
              &nbsp;
            </Typography>
            {isOrganization && headerMenu}
          </Toolbar>
          )}
      </AppBar>
      <SignUpModal
        open={isOpenSignUp}
        onClose={updateSignUpModalState(false)}
        onChangeModal={handleChangeModal(false, true)}
      />
      <LoginModal
        open={isOpenLogin}
        onClose={updateLoginModalState(false)}
        onChangeModal={handleChangeModal(true, false)}
      />
    </>
  );
};

export const AppHeader = memo(AppHeaderComponent);
