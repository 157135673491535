import Charity from 'models/Charity';
import CharityDto from 'api/dtos/charityDto';

/**
 * Map DTO to Charity model.
 */
export const mapDtoToCharity = (dto: CharityDto): Charity => new Charity({
  id: dto.id,
  name: dto.name,
  deletedAt: dto.deleted_at ? new Date(dto.deleted_at) : null,
  status: dto.status,
  isFixPrice: dto.is_fix_price,
  fixPrice: Number(dto.fix_price_value),
  isCommented: dto.is_commented,
  commentTitle: dto.comment_title,
});

/**
 * Map DTO to Charities list.
 */
export function mapDtoToCharities(dtos: CharityDto[] = []): Charity[] {
  return dtos.map(mapDtoToCharity);
}
