import React, { useState, memo } from 'react';
import { FormControl, FormHelperText, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, BaseDatePickerProps } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import isValid from 'date-fns/isValid';
import DateFnsUtils from '@date-io/date-fns';

import { FormikValues, useField, useFormikContext } from 'formik';
import styles from './BaseDatepicker.module.css';

interface BaseDatepickerProps extends BaseDatePickerProps {
  /**
   * Unique id.
   */
  id: string;
  /**
   * Text label.
   */
  label: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Optional date format.
   * @default MM/dd/yyyy
   * @example 22/02/2021
   */
  format?: string;
  /**
   * Optional text placeholder.
   */
  placeholder?: string;
  /**
   * Check show icon calendar or not.
   */
  isShowIcon?: boolean;
}

const BaseDatepickerComponent = ({
  id, label, name, format, placeholder, isShowIcon, ...props
}: BaseDatepickerProps): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);
  const [value, setValue] = useState<Date | null>(values[name] || null);

  const handleChange = (date: Date | null) => {
    setValue(date);

    if (date && isValid(date)) {
      setFieldValue(name, date.toISOString());
    }
  };

  const configDatepicker = {
    ...field,
    ...props,
    value,
    placeholder,
    id,
    label,
    format,
    onChange: handleChange,
  };

  const configFormControl = {
    error: false,
    fullWidth: true,
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  const inputProps = isShowIcon
    ? {
      endAdornment: (
        <InputAdornment position="start">
          <DateRangeIcon />
        </InputAdornment>
      ),
    }
    : undefined;

  return (
    <FormControl {...configFormControl} className={styles.host}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          {...configDatepicker}
          className={styles.input}
          disableToolbar
          autoOk
          variant="inline"
          inputVariant="outlined"
          InputLabelProps={{ shrink: true, className: styles.label, htmlFor: id }}
          InputProps={inputProps}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseDatepickerComponent.defaultProps = {
  format: 'MM/dd/yyyy',
  placeholder: '',
  isShowIcon: false,
};

export const BaseDatepicker = memo(BaseDatepickerComponent);
