import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { List, Typography } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { BaseButtonLink, Loading } from 'components';
import { UserPaymentItem } from 'features/user/components/UserPaymentItem';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import { resetUserPayment } from 'store/UserPayment';

import styles from './PaymentInfoPage.module.css';

const PaymentInfoPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();

  const { list, loading } = useAppSelector((state) => state.userPayment);

  useEffect(() => {
    if (!params.id) {
      dispatch(resetUserPayment());
    }
  }, [dispatch, params.id]);

  const payments = list?.results || [];

  return (
    <>
      <Helmet>
        <title>Payment Info Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="Add/Change Payment" isBackToMenu />
        <List className={styles.listWrapper}>
          {loading && <Loading />}
          {payments.length === 0 && !loading
            ? <Typography variant="body2" className={styles.noDataText}>No data to display yet</Typography>
            : payments.map((payment) => (
              <UserPaymentItem key={payment.id} path={`${location.pathname}/edit/${payment.id}`} payment={payment} />
            ))}
        </List>
        <div className={styles.buttonWrapper}>
          <BaseButtonLink
            size="large"
            variant="outlined"
            disableElevation
            fullWidth
            className={styles.button}
            to={`${location.pathname}/add`}
          >
            + Add new payment method
          </BaseButtonLink>
        </div>
      </div>
    </>
  );
};

export default PaymentInfoPage;
