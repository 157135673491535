import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ProfileEditForm } from 'features/user/components/ProfileEditForm';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import styles from './ProfileEditPage.module.css';

const ProfileEditPage = (): JSX.Element => {
  const params = useParams();

  return (
    <>
      <Helmet>
        <title>Profile Edit Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="My Profile Info" />
        <ProfileEditForm path={`/${params.slug}`} />
      </div>
    </>
  );
};

export default ProfileEditPage;
