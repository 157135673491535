import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Token } from 'models/Auth';
import ApiError from 'models/ApiError';

import { state } from './state';
import { updatePassword } from './dispatchers';

const authSlice = createSlice({
  name: 'auth',
  initialState: state,
  reducers: {
    setAuthStart(state) {
      state.loading = true;
      state.error = null;
    },
    setAuthSuccess(state, { payload }: PayloadAction<Token>) {
      state.token = payload.token;
      state.loading = false;
    },
    setAuthFailed(state, { payload }: PayloadAction<ApiError>) {
      state.error = payload;
      state.loading = false;
    },
    setLogoutStart(state) {
      state.loading = true;
      state.error = null;
    },
    setLogoutSuccess(state) {
      state.token = null;
      state.loading = false;
    },
    setLogoutFailed(state, { payload }: PayloadAction<ApiError>) {
      state.error = payload;
      state.loading = false;
    },
    setResetPasswordStart(state) {
      state.loading = true;
      state.error = null;
    },
    setResetPasswordSuccess(state) {
      state.loading = false;
    },
    setResetPasswordFailed(state, { payload }: PayloadAction<ApiError>) {
      state.error = payload;
      state.loading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePassword.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setAuthStart,
  setAuthSuccess,
  setAuthFailed,
  setLogoutStart,
  setLogoutSuccess,
  setLogoutFailed,
  setResetPasswordStart,
  setResetPasswordSuccess,
  setResetPasswordFailed,
} = authSlice.actions;

export const auth = authSlice.reducer;
