/**
 *  API errors model class.
 */
export default class ApiError {
  public readonly errors?: {
    /**
     * Error field.
     */
    field: string;
    /**
     * Description of error.
     */
    messages: string[];
  };

  /**
   * Global error message.
   */
  public readonly message: string;

  constructor(data: ApiError) {
    this.errors = data.errors;
    this.message = data.message;
  }
}
