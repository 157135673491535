import React, { memo, useCallback, useEffect } from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import { FormHelperText, Grid } from '@material-ui/core';

import { CONFIG } from 'config';
import { BaseInput } from 'components';

import styles from './PaymentCard.module.css';

// Custom CSS passed via URL encode. Need to customize controls.
const CSS = `
*,*::before,*::after{box-sizing:inherit}
body{margin:0}.error{color:#b3251b}
input{width:100%}
label{font: 500 15px/15px sans-serif;color:#2e384d;display:block;margin-bottom:8px;margin-top:16px}
label:first-child{margin-top: 0}
input,select{height:44px;border-radius:8px;background:#fff;
border:1px solid #dbdfe3;min-width:64px;padding:0 14px;box-sizing:border-box;font-size:16px}
#cccvvfield{width:68px}
input:focus,select:focus{border:2px solid #2083a5;outline:0}
br{display:none}
`;
const SRC = CONFIG.cardPointIframeUrl || '';
const PARAMS = '?useexpiry=true&usecvv=true&sendcssloadedevent=true';
const PAYMENT_IFRAME_SRC = `${SRC}${PARAMS}&css=${encodeURIComponent(CSS)}`;

interface PaymentCardProps {
  /**
   * Check if card used by sidebar.
   */
  isCardSidebar?: boolean;
}

const PaymentCardComponent = ({
  isCardSidebar,
}: PaymentCardProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [field, meta] = useField('token');

  const getPaymentToken = useCallback((event: MessageEvent) => {
    if (typeof event.data === 'string' && event.data.startsWith('{')) {
      const parsed = JSON.parse(event.data);

      // eslint-disable-next-line no-prototype-builtins
      if (parsed.hasOwnProperty('message')) {
        setFieldValue(field.name, parsed.message);
        setFieldValue('expiry', parsed.expiry);
      }
    }
  }, [setFieldValue, field.name]);

  useEffect(() => {
    window.addEventListener('message', getPaymentToken);
    return () => {
      window.removeEventListener('message', getPaymentToken);
    };
  }, [getPaymentToken]);

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    helperText = meta.error;
  }

  const uniqueCardId = `cardName-${new Date().toJSON()}`;
  const smName = isCardSidebar ? 12 : 9;
  const smZip = isCardSidebar ? 6 : 3;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={smName}>
        <BaseInput
          label="Name on card"
          id={uniqueCardId}
          name="cardName"
        />
      </Grid>
      <Grid item xs={6} sm={smZip}>
        <BaseInput
          label="ZIP"
          name="zip"
        />
      </Grid>
      <Grid item xs={12} className="emptyGrid" />
      <Grid item xs={12} sm={smName}>
        <iframe
          title="Credit/Debit Card"
          id="token"
          name="token"
          src={PAYMENT_IFRAME_SRC}
          frameBorder="0"
          scrolling="no"
          className={styles.host}
        />
        <FormHelperText error>{helperText}</FormHelperText>
      </Grid>
    </Grid>
  );
};

PaymentCardComponent.defaultProps = {
  isCardSidebar: false,
};

export const PaymentCard = memo(PaymentCardComponent);
