import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Form, useFormik, FormikProvider, FormikHelpers,
} from 'formik';
import {
  Box, Divider, Grid, Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { createUserPayment, getUserPayments, updateUserPaymentById } from 'store/UserPayment';
import {
  BaseButton, BaseButtonLink, BaseCheckbox, BaseInput, ERROR, SUCCESS,
} from 'components';
import { getValidationErrors } from 'utils/transformErrors';
import { preventNonNumericValues } from 'utils/preventNonNumericValues';
import { UserSavePaymentControl } from 'models/UserSavePayment';
import { PaymentMethod } from 'models/enums';
import ApiError from 'models/ApiError';

import { formValidation, initialFormState, PaymentFormState } from './formSettings';

import styles from './PaymentAchForm.module.css';

interface PaymentAchFormProps {
  /**
   * Path.
   */
  path: string;
  /**
   * Payment id.
   */
  paymentId?: number;
  /**
   * Method for opening DeletePayment pop up.
   */
  onDeletePayment?: () => void;
}

const PaymentAchFormComponent = ({
  path, paymentId, onDeletePayment: onOpenDeletePaymentModal,
}: PaymentAchFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { details, processing } = useAppSelector((state) => state.userPayment);

  const isEditMode = Boolean(paymentId);

  const handleSubmit = async (values: PaymentFormState, { setErrors }: FormikHelpers<PaymentFormState>) => {
    try {
      const payment: UserSavePaymentControl = {
        ...values,
        paymentMethod: details?.paymentMethod ?? PaymentMethod.Ach,
      };

      if (isEditMode && paymentId) {
        await dispatch(updateUserPaymentById({ payment, id: paymentId })).unwrap();

        snackbar.enqueueSnackbar('Your payment has been changed successfully', SUCCESS);
      } else {
        const data = {
          ...payment,
          token: `${values.routingNumber}/${values.accountNumber}`,
        };

        await dispatch(createUserPayment({ payment: data })).unwrap();

        snackbar.enqueueSnackbar('Your payment has been created successfully', SUCCESS);
      }

      await dispatch(getUserPayments());
      navigate(path);
    } catch (error) {
      const apiError = error as ApiError;

      if (apiError.errors) {
        setErrors(getValidationErrors(apiError.errors));
      } else {
        snackbar.enqueueSnackbar(apiError.message, ERROR);
      }
    }
  };

  const formInitValues = initialFormState(isEditMode ? details : null);

  const formik = useFormik({
    initialValues: formInitValues,
    onSubmit: handleSubmit,
    validationSchema: formValidation,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate className={styles.host}>
        <Grid container spacing={2} className={styles.controlsWrapper}>
          <Grid item xs={12}>
            <BaseInput
              label="Please provide a name for this payment method"
              id="name"
              name="name"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInput
              label="Account Holder Name"
              id="achName"
              name="achName"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInput
              label="Routing Number"
              id="routingNumber"
              name="routingNumber"
              inputProps={{
                maxLength: 12,
              }}
              onKeyDown={preventNonNumericValues}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInput
              label="Account Number"
              id="accountNumber"
              name="accountNumber"
              inputProps={{
                maxLength: 12,
              }}
              onKeyDown={preventNonNumericValues}
              required
            />
          </Grid>
          <Grid item>
            <BaseCheckbox
              id="isDefaultPayment"
              name="isDefaultPayment"
              label="Set as default"
            />
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          {isEditMode && (
            <Box textAlign="center" paddingBottom={2}>
              <BaseButton onClick={onOpenDeletePaymentModal} danger>
                <DeleteIcon />
                <Typography variant="body1">Delete payment?</Typography>
              </BaseButton>
            </Box>
          )}
          <Divider />
          <Grid container spacing={2} className={styles.buttons}>
            <Grid item xs={6}>
              <BaseButtonLink
                size="large"
                variant="outlined"
                to={path}
                disableElevation
                fullWidth
              >
                Cancel
              </BaseButtonLink>
            </Grid>
            <Grid item xs={6}>
              <BaseButton
                size="large"
                color="primary"
                loading={processing}
                type="submit"
                variant="contained"
                disableElevation
                fullWidth
              >
                Save
              </BaseButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    </FormikProvider>
  );
};

PaymentAchFormComponent.defaultProps = {
  paymentId: null,
  onDeletePayment: () => {},
};

export const PaymentAchForm = memo(PaymentAchFormComponent);
