import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { UserProfileMenuCard } from 'features/user/components/UserProfileMenuCard';
import { UserProfileMenu } from 'features/user/components/UserProfileMenu';
import { logout } from 'store/Auth';

import styles from './UserProfilePage.module.css';

const UserProfilePage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();
  const { loading } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  /**
   * Method for closing Profile pop up.
   */
  const handleCloseProfile = () => {
    navigate(`/${params.slug}`);
  };

  /**
   * Method for log out of the session and closes the Profile pop up.
   */
  const handleLogout = async () => {
    await dispatch(logout());
    navigate(`/${params.slug}`);
  };

  return (
    <>
      <Helmet>
        <title>User Profile Page</title>
      </Helmet>

      <div className={styles.container}>
        <UserProfileMenuCard
          path={`/${params.slug}` || '/'}
          onCloseProfile={handleCloseProfile}
        />
        <UserProfileMenu path={location.pathname} />
        <List disablePadding className={styles.menu} component="nav">
          <ListItem
            button
            className={clsx(styles.navItem, styles.logOutButton)}
            disabled={loading}
            onClick={handleLogout}
          >
            <ListItemText primary="Log Out" className={styles.link} />
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default UserProfilePage;
