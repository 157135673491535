import { Frequency } from './enums';

/**
 * Payment frequency model class.
 */
export default class PaymentFrequency {
  /**
   * Unique Id.
   */
  public readonly id: Frequency;

  /**
   * Name of payment frequency.
   */
  public readonly name: string;

  constructor(data: PaymentFrequency) {
    this.id = data.id;
    this.name = data.name;
  }
}
