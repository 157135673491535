import React, { memo, ChangeEvent, ReactNode } from 'react';
import { useField, useFormikContext, FormikValues } from 'formik';
import {
  FormLabel, LinearProgress, FormControl, FormHelperText, SelectProps, Select, MenuItem,
} from '@material-ui/core';

import styles from './BaseSelect.module.css';

interface BaseSelectProps extends SelectProps {
  /**
   * Text label.
   */
  label: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Option key name where get key.
   */
  itemKey: string;
  /**
   * Option key name where get value.
   */
  itemValue: string;
  /**
   * Array of options.
   */
  options: { [key: string]: any }[];
  /**
   * State of loading data.
   */
  loading?: boolean;
}

const BaseSelectComponent = ({
  id, label, name, options, itemKey, itemValue, loading, onChange, ...props
}: BaseSelectProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);

  const handleChange = (event: ChangeEvent<{ value: unknown }>, child: ReactNode) => {
    const { value } = event.target;
    if (!value) {
      setFieldValue(name, '');
    } else {
      setFieldValue(name, value);
    }

    if (onChange) {
      onChange(event, child);
    }
  };

  const configFormControl = {
    fullWidth: true,
    error: false,
  };

  let helperText: string | null = null;

  const configSelect = {
    ...field,
    ...props,
    id,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  return (
    <FormControl {...configFormControl} className={styles.host}>
      <FormLabel className={styles.label} htmlFor={id}>{label}</FormLabel>
      <div className={styles.wrapper}>
        <Select
          {...configSelect}
          className={styles.select}
          error={configFormControl.error}
          variant="outlined"
        >
          {options.map((item) => (
            <MenuItem key={item[itemKey]} value={item[itemKey]} disabled={item.disabled}>
              {item[itemValue]}
            </MenuItem>
          ))}
        </Select>
        {loading && <LinearProgress className={styles.progressbar} />}
      </div>
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseSelectComponent.defaultProps = {
  loading: false,
};

export const BaseSelect = memo(BaseSelectComponent);
