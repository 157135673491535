import React, { memo, ReactNode } from 'react';
import {
  Accordion, AccordionProps, AccordionDetails, AccordionSummary, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './BaseAccordion.module.css';

interface BaseAccordionProps extends AccordionProps {
  /**
   * Unique Id.
   */
  id: string;
  /**
   * Heading.
   */
  heading: string;
  /**
   * Icon.
   */
  icon: ReactNode;
}

const BaseAccordionComponent = ({
  id, heading, icon, children, ...props
}: BaseAccordionProps): JSX.Element => (
  <Accordion {...props} className={styles.host}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`${id}-content`}
      id={`${id}-header`}
    >
      <div className={styles.summary}>
        <div className={styles.icon}>
          {icon}
        </div>
        <Typography variant="h6" className={styles.heading}>{heading}</Typography>
      </div>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
);

export const BaseAccordion = memo(BaseAccordionComponent);
