import User from 'models/User';
import UserDto from 'api/dtos/userDto';

/**
 * Map DTO to User model.
 */
export const mapDtoToUser = (dto: UserDto): User => new User({
  id: dto.id,
  lastName: dto.last_name,
  firstName: dto.first_name,
  email: dto.email,
  phone: dto.phone,
  avatarUrl: dto.avatar_url,
  createdAt: new Date(dto.created_at),
  role: dto.role,
});

/**
 * Map User model to DTO.
 */
export const mapUserToDto = (payload: User): UserDto => ({
  id: payload.id,
  last_name: payload.lastName,
  first_name: payload.firstName,
  email: payload.email,
  phone: payload.phone,
  avatar_url: payload.avatarUrl,
  created_at: payload.createdAt?.toISOString(),
  role: payload.role,
});
