import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import MainLayout from 'layout/MainLayout/MainLayout';
import { donationRoutes } from 'features/donation/routes';
import { authRoutes } from 'features/auth/routes';

const PageNotFound = lazy(() => import('components/PageNotFound/PageNotFound'));

export const router: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      ...donationRoutes,
      ...authRoutes,
    ],
  },
  { path: '*', element: <PageNotFound /> },
];
