import PaymentFrequency from 'models/PaymentFrequency';
import PaymentFrequencyDto from 'api/dtos/paymentFrequencyDto';
import ResponseDto from 'api/dtos/responseDto';
import PagedList from 'models/PagedList';
import { mapDtoToPagination } from './paginationMapper';

/**
 * Map DTO to PaymentFrequency model.
 */
export const mapDtoToPaymentFrequency = (dto: PaymentFrequencyDto): PaymentFrequency => new PaymentFrequency({
  id: dto.id,
  name: dto.name,
});

/**
 * Map DTO to PaymentFrequency list.
 */
export function mapDtoToPaymentFrequencies(dtos: ResponseDto<PaymentFrequencyDto>): PagedList<PaymentFrequency> {
  return new PagedList<PaymentFrequency>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToPaymentFrequency),
  });
}
