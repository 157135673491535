import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { store } from 'store';
import { AppSnackbar } from 'components';
import getCssVarValue from 'utils/getCssVarValue';

import App from './App';
import './theme/index.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Manrope"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: getCssVarValue('--primary-color'),
    },
    secondary: {
      main: getCssVarValue('--secondary-color'),
    },
    info: {
      main: getCssVarValue('--info-color'),
    },
    error: {
      main: getCssVarValue('--error-color'),
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <AppSnackbar>
              <App />
            </AppSnackbar>
          </HelmetProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
