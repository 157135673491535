import PaymentFrequencyDto from 'api/dtos/paymentFrequencyDto';
import PaymentFrequency from 'models/PaymentFrequency';
import { mapDtoToPaymentFrequencies } from 'api/mappers/paymentFrequencyMapper';
import ResponseDto from 'api/dtos/responseDto';
import PagedList from 'models/PagedList';

import { http } from '../index';

/**
 * Fetch payment frequencies.
 */
export const fetchPaymentFrequencies = async (): Promise<PagedList<PaymentFrequency>> => {
  try {
    const { data } = await http.get<ResponseDto<PaymentFrequencyDto>>('/payment-frequency');

    return mapDtoToPaymentFrequencies(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
