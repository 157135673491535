import React, { lazy } from 'react';

const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage/ResetPasswordPage'));

export const authRoutes = [
  {
    path: ':slug/password/reset/:token',
    element: <ResetPasswordPage />,
  },
];
