import React, { memo } from 'react';
import { useSnackbar } from 'notistack';
import {
  Formik, Form, FormikHelpers,
} from 'formik';
import { Grid } from '@material-ui/core';

import { BaseButton, BaseInput, BaseInputPassword } from 'components';
import { ERROR } from 'components/AppSnackbar';
import { getValidationErrors } from 'utils/transformErrors';
import transformAPIErrors from 'utils/transformAPIErrors';
import { ServerValidationError } from 'utils/exceptions';
import { login } from 'store/Auth';
import { useAppSelector, useAppDispatch } from 'store/rootReducer';
import Auth from 'models/Auth';
import { getUser } from 'store/User';

import { formValidation, initialFormState } from './formSettings';
import styles from './LoginForm.module.css';

interface LoginFormProps {
  /**
   * Method for to go to Forgot Password dialog.
   */
  onForgot: () => void;
  /**
   * Method for close Login dialog in case of successful submit .
   */
  onSuccess: () => void;
}

const LoginFormComponent = ({ onForgot, onSuccess }: LoginFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const { loading: loadingAuth } = useAppSelector((state) => state.auth);
  const { loading: loadingUser } = useAppSelector((state) => state.user);

  const handleSubmit = async (values: Auth, { setErrors }: FormikHelpers<Auth>) => {
    try {
      await dispatch(login(values));
      await dispatch(getUser());

      onSuccess();
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      if (error instanceof ServerValidationError) {
        setErrors(getValidationErrors(serverErrors.errors));
      } else {
        snackbar.enqueueSnackbar(serverErrors.message, ERROR);
      }
    }
  };

  return (
    <Formik
      initialValues={{ ...initialFormState }}
      onSubmit={handleSubmit}
      validationSchema={formValidation}
      validateOnBlur={false}
    >
      <Form noValidate className="app-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BaseInput
              label="Email"
              id="email"
              name="email"
              type="email"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInputPassword
              id="password"
              label="Password"
              name="password"
              required
            />
            <BaseButton
              color="primary"
              className={styles.buttonRedirect}
              onClick={onForgot}
            >
              Forgot Password?
            </BaseButton>
          </Grid>
          <Grid item xs={12}>
            <BaseButton
              size="large"
              color="primary"
              type="submit"
              variant="contained"
              disableElevation
              fullWidth
              loading={loadingAuth || loadingUser}
              className={styles.button}
            >
              Sign In
            </BaseButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export const LoginForm = memo(LoginFormComponent);
