import React, { memo } from 'react';
import clsx from 'clsx';
import { DataGrid, DataGridProps, GridFeatureMode } from '@material-ui/data-grid';

import { BaseDataTableNoData } from './BaseDataTableNoData';
import { BaseLoadingOverlay } from './BaseLoadingOverlay';

import styles from './BaseDataTable.module.css';

interface BaseDataTableProps extends DataGridProps {
  /**
   * Striped table.
   */
  zebra?: boolean;
  /**
   * Sorting mode.
   */
  sortingMode?: GridFeatureMode;
  /**
   * Pagination mode.
   */
  paginationMode?: GridFeatureMode;
}

const BaseDataTableComponent = ({
  zebra, sortingMode, paginationMode, components, hideFooterPagination, rows, loading, onPageSizeChange, ...props
}: BaseDataTableProps) => {
  const tableComponents = {
    ...components,
    NoRowsOverlay: BaseDataTableNoData,
    LoadingOverlay: BaseLoadingOverlay,
  };

  const data = loading ? [] : rows;

  return (
    <DataGrid
      {...props}
      loading={loading}
      rows={data}
      hideFooterPagination={hideFooterPagination}
      className={clsx(styles.table, { [styles.zebra]: zebra, [styles.noPagination]: hideFooterPagination })}
      rowsPerPageOptions={[10, 25, 50]}
      disableColumnMenu
      disableSelectionOnClick
      components={tableComponents}
      sortingMode={sortingMode}
      paginationMode={paginationMode}
    />
  );
};

BaseDataTableComponent.defaultProps = {
  zebra: false,
  sortingMode: 'server',
  paginationMode: 'server',
};

export const BaseDataTable = memo(BaseDataTableComponent);
