import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * Convert phone number to international format.
 */
export const getInternationalFormat = (phone: string | null | undefined): string => {
  if (!phone) {
    return '';
  }

  return parsePhoneNumber(phone)?.formatInternational() ?? '';
};

/**
 * Convert phone number to phone format.
 */
export const getNumberFormat = (phone: string | null | undefined): string => {
  if (!phone) {
    return '';
  }

  return parsePhoneNumber(phone, 'US')?.number ?? '';
};
