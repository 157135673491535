/**
 *  User transaction model class.
 */
export default class UserTransaction {
  /**
   * Transaction id.
   */
  public readonly id: number;

  /**
   * Transaction amount.
   */
  public readonly amount: number;

  /**
   * Organization donated to name.
   */
  public readonly name: string;

  /**
   * Date of create.
   */
  public readonly createdAt: Date;

  /**
   * Check if payment is recurring.
   */
  public readonly isReoccurring: boolean;

  constructor(data: UserTransactionConstructorArgs) {
    this.id = data.id;
    this.amount = data.amount;
    this.name = data.name;
    this.createdAt = data.createdAt;
    this.isReoccurring = data.isReoccurring;
  }
}

type UserTransactionConstructorArgs = UserTransaction;
