import { CONFIG } from 'config';
import OrganizationDto from 'api/dtos/organizationDto';
import Organization from 'models/Organization';
import { Donation, DonationResponse } from 'models/Donation';
import PaymentToken, { PaymentTokenResponse } from 'models/PaymentToken';
import { mapDtoToOrganization } from 'api/mappers/organizationMapper';
import { DonationResponseDto } from 'api/dtos/donationDto';
import { mapDtoToPaymentToken, mapPaymentTokenToDto } from 'api/mappers/paymentTokenMapper';
import { mapDonationToDto, mapDtoToDonation } from 'api/mappers/donationMapper';

import { http } from '../index';

/**
 * Fetch organization by slug.
 */
export const fetchOrganizationBySlug = async (slug: string, preview: boolean): Promise<Organization> => {
  const { data } = await http.get<OrganizationDto>(`/organizations/detail/${slug}`, {
    params: { preview },
  });

  return mapDtoToOrganization(data);
};

/**
 * Fetch payment token.
 */
export const fetchPaymentToken = async (payload: PaymentToken): Promise<PaymentTokenResponse> => {
  const body = mapPaymentTokenToDto(payload);
  const url = CONFIG.cardPointUrl || '';

  try {
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    return mapDtoToPaymentToken(await response.json());
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Create donation.
 */
export const createDonation = async (payload: Donation): Promise<DonationResponse> => {
  const dto = mapDonationToDto(payload);

  const { data } = await http.post<DonationResponseDto>('/organizations/payments', dto);

  return mapDtoToDonation(data);
};
