import React, {
  memo, useState, MouseEvent, useEffect,
} from 'react';
import {
  DialogProps, Dialog, DialogContent, DialogTitle, Box, IconButton, DialogActions, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { AuthLayout } from 'layout/AuthLayout/AuthLayout';
import { BaseButton } from 'components';

import { LoginForm } from '../LoginForm';
import { ForgotPasswordForm } from '../ForgotPasswordForm';
import styles from './LoginModal.module.css';

interface LoginModalComponentProps extends DialogProps {
  onChangeModal: () => void;
  onSuccess?: () => void;
}

const LoginModalComponent = ({
  onChangeModal,
  open,
  onClose,
  onSuccess,
  ...props
}: LoginModalComponentProps): JSX.Element => {
  const [isShowForgotPassword, setShowForgotPassword] = useState(false);
  const [isShowLogin, setShowLogin] = useState(false);

  useEffect(() => {
    setShowLogin(open);
  }, [open]);

  /**
   * It's closing item checking if the click is outside the this item.
   */
  const handleClosePrevent = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      if (reason !== 'backdropClick') {
        onClose(event, reason);
        setShowForgotPassword(false);
      }
    }
  };

  /**
   * It's closing item.
   */
  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    // onClose is optional props. Need to pass event and reason 'escapeKeyDown' for closing by Esc.
    if (onClose) {
      onClose(event, 'escapeKeyDown');
      setShowForgotPassword(false);
    }
  };

  /**
   * It's opening ForgotPassword dialog.
   */
  const handleOpenForgotPassword = () => {
    setShowForgotPassword(true);
    setShowLogin(false);
  };

  /**
   * It's closing ForgotPassword dialog.
   */
  const handleCloseForgotPassword = () => {
    setShowForgotPassword(false);
    setShowLogin(true);
  };

  /**
   * It's closing Login pop up.
   */
  const handleCloseLoginModal = () => {
    handleClose({} as MouseEvent<HTMLButtonElement>);
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <>
      <Dialog
        {...props}
        open={isShowLogin}
        aria-labelledby="alert-dialog-title"
        onClose={(event, reason) => handleClosePrevent(event, reason)}
        disableEscapeKeyDown
        className={styles.host}
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div className={styles.container}>
            <AuthLayout>
              <Box textAlign="center" mb={5}>
                <Typography variant="h4" className={styles.title}>Sign In</Typography>
              </Box>
              <LoginForm onForgot={handleOpenForgotPassword} onSuccess={handleCloseLoginModal} />
            </AuthLayout>
          </div>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Box textAlign="center">
            Don&lsquo;t have an account?&nbsp;
            <BaseButton color="primary" onClick={onChangeModal}>Sign Up</BaseButton>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        {...props}
        open={isShowForgotPassword}
        aria-labelledby="alert-dialog-title"
        onClose={(event, reason) => handleClosePrevent(event, reason)}
        disableEscapeKeyDown
        className={styles.host}
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div className={styles.container}>
            <AuthLayout>
              <ForgotPasswordForm onLogin={handleCloseForgotPassword} onSuccess={handleCloseLoginModal} />
            </AuthLayout>
          </div>
        </DialogContent>
        <DialogActions className={styles.actions} />
      </Dialog>
    </>
  );
};

LoginModalComponent.defaultProps = {
  onSuccess: undefined,
};

export const LoginModal = memo(LoginModalComponent);
