import React, { ChangeEvent, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Form, FormikHelpers, useFormik, FormikProvider,
} from 'formik';
import MoneyInput from '@rschpdr/react-money-input';
import {
  Box, Divider, Grid, Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  BaseButton, BaseButtonLink, BaseInput,
} from 'components';
import { ERROR, SUCCESS } from 'components/AppSnackbar';
import ApiError from 'models/ApiError';
import { getValidationErrors } from 'utils/transformErrors';
import { useAppSelector, useAppDispatch } from 'store/rootReducer';
import { getReoccurringPayments, getUserPayments, updateReoccurringPaymentById } from 'store/UserPayment';
import {
  DAYS, getDayOfWeek, getFormattedDate, SHORT_FORMAT,
} from 'utils/date';
import { Frequency, PaymentMethod } from 'models/enums';
import ReoccurringPayment from 'models/ReoccurringPayment';

import PaymentToken from 'models/PaymentToken';
import { formValidation, initialFormState, ReoccurringFormState } from './formSettings';
import { PaymentMethodReoccurring } from '../PaymentMethodReoccurring';
import { ReoccurringUpdate } from '../ReoccurringUpdate';

import styles from './ReoccurringForm.module.css';

interface ReoccurringFormProps {
  /**
   * Path.
   */
  path: string;
  /**
   * Recurring payment id.
   */
  reoccurringId: number;
  /**
   * Method for opening StopReoccurring pop up.
   */
  onOpenStopReoccurring: () => void;
}

const ReoccurringFormComponent = ({
  path, reoccurringId, onOpenStopReoccurring,
}: ReoccurringFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { details, loading } = useAppSelector((state) => state.userPayment.reoccurring);

  const handleSubmit = async (values: ReoccurringFormState, { setErrors }: FormikHelpers<ReoccurringFormState>) => {
    try {
      const chargeAtWeekDay = values.chargeAt === formInitValues.chargeAt
        ? DAYS[getDayOfWeek(values.chargeAt)] : values.chargeAt;

      const chargeAt = values.frequencyId === Frequency.Monthly
        ? getFormattedDate(values.chargeAt, SHORT_FORMAT) : chargeAtWeekDay;

      const reoccurringPayment = new ReoccurringPayment({
        ...values,
        frequency: values.frequencyId,
        chargeAt,
        last4: values.isSavedPaymentMethod ? values.last4 : (values.token.toString().slice(-4) || ''),
      });

      const paymentToken = new PaymentToken({
        account: `${values.routingNumber}/${values.accountNumber}`,
      });

      await dispatch(updateReoccurringPaymentById({
        payment: reoccurringPayment,
        paymentToken,
        id: reoccurringId,
      })).unwrap();
      snackbar.enqueueSnackbar('Your recurring payment has been changed successfully', SUCCESS);

      dispatch(getReoccurringPayments());
      if (reoccurringPayment.isFutureUse === true) {
        dispatch(getUserPayments());
      }
      navigate(path);
    } catch (error) {
      const apiError = error as ApiError;

      if (apiError.errors) {
        setErrors(getValidationErrors(apiError.errors));
      } else {
        snackbar.enqueueSnackbar(apiError.message, ERROR);
      }
    }
  };

  /**
   * It's changing value 'amount' field.
   */
  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    formik.setFieldValue('amount', Number(value));
  };

  const formInitValues = initialFormState(details);

  const formik = useFormik({
    initialValues: formInitValues,
    onSubmit: handleSubmit,
    validationSchema: formValidation,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const isNoTokenNewCard = formik.values.token === ''
    && !formik.values.isSavedPaymentMethod
    && formik.values.paymentMethod === PaymentMethod.Card;

  return (
    <FormikProvider value={formik}>
      <Form noValidate className={styles.form}>
        <Grid container spacing={2} className={styles.controlsWrapper}>
          <Grid item xs={12}>
            <Typography variant="body2" className={styles.labelField}>
              Donated to
            </Typography>
            <Typography variant="body1">
              {details?.donatedTo}
            </Typography>
          </Grid>
          <Grid item xs={5} className={styles.amountContainer}>
            <MoneyInput
              id="amount"
              label="Amount"
              name="amount"
              customInput={BaseInput}
              value={formik.values.amount}
              max={99999999}
              onChange={handleAmountChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ReoccurringUpdate />
          </Grid>
          <Grid item xs={12}>
            <PaymentMethodReoccurring />
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          <Box textAlign="center" paddingBottom={2}>
            <BaseButton onClick={onOpenStopReoccurring} danger>
              <DeleteIcon />
              <Typography variant="body1">Stop this recurring payment?</Typography>
            </BaseButton>
          </Box>
          <Divider />
          <Grid container spacing={2} className={styles.buttons}>
            <Grid item xs={6}>
              <BaseButtonLink
                size="large"
                variant="outlined"
                to={path}
                disableElevation
                fullWidth
              >
                Cancel
              </BaseButtonLink>
            </Grid>
            <Grid item xs={6}>
              <BaseButton
                size="large"
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
                fullWidth
                disabled={isNoTokenNewCard}
                loading={loading}
              >
                Save
              </BaseButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    </FormikProvider>
  );
};

export const ReoccurringForm = memo(ReoccurringFormComponent);
