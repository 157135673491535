import UserSavePaymentDto from 'api/dtos/userSavePaymentDto';
import UserSavePayment from 'models/UserSavePayment';

/**
 * Map User payment model to DTO.
 */
export const mapUserSavePaymentToDto = (payload: UserSavePayment): UserSavePaymentDto => ({
  payment_method: payload.paymentMethod,
  last_4: payload.last4,
  name: payload.name,
  card_name: payload.cardName,
  ach_name: payload.achName,
  is_default_payment: payload.isDefaultPayment,
  token: payload.token,
  expiry: payload.expiry,
  zip: payload.zip,
});
