import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchOrganizationBySlug, fetchPaymentToken, createDonation } from 'api/services/Organization';
import { Donation, DonationResponse } from 'models/Donation';

/**
 * Get organization details by slug.
 */
export const getOrganizationBySlug = createAsyncThunk(
  'organization/getOrganization',
  async ({ slug, preview }: { slug: string, preview: boolean }) => {
    try {
      return await fetchOrganizationBySlug(slug, preview);
    } catch (error) {
      throw Error(error as string);
    }
  },
);

/**
 * Make donation method.
 */
export const makeDonation = createAsyncThunk(
  'organization/makeDonation',
  async (donation: Donation): Promise<DonationResponse> => {
    try {
      if (donation.userPaymentId) {
        return await createDonation(donation);
      }

      const { token } = await fetchPaymentToken(donation);
      const paymentSettings = {
        ...donation,
        last4: token.slice(-4),
        token,
      };

      return await createDonation(paymentSettings);
    } catch (error) {
      throw Error(error as string);
    }
  },
);
