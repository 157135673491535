import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import {
  createSession,
  deleteSession,
  updateSession,
  resetPassword as reset,
  confirmResetPassword as confirmReset,
  setNewPassword,
} from 'api/services/Auth';
import { ResetPassword } from 'features/auth/components/ForgotPasswordForm/formSettings';
import ApiError from 'models/ApiError';
import Auth, { ConfirmResetPassword } from 'models/Auth';
import { resetUser } from 'store/User/userSlice';
import transformAPIErrors from 'utils/transformAPIErrors';

import { AppThunk } from '../index';
import {
  setAuthStart,
  setAuthSuccess,
  setAuthFailed,
  setLogoutStart,
  setLogoutSuccess,
  setLogoutFailed,
  setResetPasswordStart,
  setResetPasswordSuccess,
  setResetPasswordFailed,
} from './authSlice';

/**
 * Login.
 */
export const login = (data: Auth): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setAuthStart());
    const response = await createSession(data);

    dispatch(setAuthSuccess(response));
  } catch (error) {
    dispatch(setAuthFailed(error as Error));

    throw error;
  }
};

/**
 * Refresh token.
 */
export const refreshToken = (token: string): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setAuthStart());

    const newToken = await updateSession(token);

    dispatch(setAuthSuccess(newToken));
  } catch (error) {
    dispatch(setAuthFailed(error as Error));

    throw error;
  }
};

/**
 * Logout.
 */
export const logout = (): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setLogoutStart());
    dispatch(resetUser());

    await deleteSession();

    dispatch(setLogoutSuccess());
  } catch (error) {
    dispatch(setLogoutFailed(error as Error));

    throw error;
  }
};

/**
 * Reset password.
 */
export const resetPassword = (resetData: ResetPassword): AppThunk<string> => async (dispatch: Dispatch) => {
  try {
    dispatch(setResetPasswordStart());

    const { data } = await reset(resetData);

    dispatch(setResetPasswordSuccess());

    return data.message;
  } catch (error) {
    dispatch(setResetPasswordFailed(error as Error));

    throw error;
  }
};

/**
 * Confirm reset password.
 */
export const confirmResetPassword = (data: ConfirmResetPassword): AppThunk<string> => async (dispatch: Dispatch) => {
  try {
    dispatch(setResetPasswordStart());

    const response = await confirmReset(data);

    dispatch(setResetPasswordSuccess());

    return response.data.message;
  } catch (error) {
    dispatch(setResetPasswordFailed(error as Error));

    throw error;
  }
};

/**
 * Update password.
 */
export const updatePassword = createAsyncThunk<void, { oldPassword: string, newPassword: string }, {
  rejectValue: ApiError;
}>(
  'user/updatePassword',
  async (data, { rejectWithValue }) => {
    try {
      return await setNewPassword(data);
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);
      return rejectWithValue(serverErrors);
    }
  },
);
