import UserPaymentDto from 'api/dtos/userPaymentDto';
import { mapDtoToUserPayment, mapDtoToUserPayments } from 'api/mappers/userPaymentMapper';
import { mapUserSavePaymentToDto } from 'api/mappers/userSavePaymentMapper';
import UserPayment from 'models/UserPayment';
import PagedList from 'models/PagedList';
import ResponseDto from 'api/dtos/responseDto';
import UserSavePayment from 'models/UserSavePayment';
import ReoccurringPayment from 'models/ReoccurringPayment';
import {
  mapDtoToReoccurringPayment,
  mapDtoToReoccurringPayments,
  mapReoccurringPaymentToDto,
} from 'api/mappers/reoccurringPaymentMapper';
import { ReoccurringPaymentResponseDto } from 'api/dtos/reoccurringPaymentDto';

import { http } from '../index';

/**
 * Fetch user payments.
 */
export const fetchUserPayments = async (): Promise<PagedList<UserPayment>> => {
  const { data } = await http.get<ResponseDto<UserPaymentDto>>('/user-payments');

  return mapDtoToUserPayments(data);
};

/**
 * Create donation.
 */
export const createUserPayment = (payload: UserSavePayment): Promise<void> => {
  const dto = mapUserSavePaymentToDto(payload);

  return http.post('/user-payments', dto);
};

/**
 * Fetch user payment.
 */
export const fetchUserPaymentById = async (id: number): Promise<UserPayment> => {
  const { data } = await http.get<UserPaymentDto>(`/user-payments/${id}`);

  return mapDtoToUserPayment(data);
};

/**
 * Update user payment.
 */
export const updateUserPayment = (payload: UserSavePayment, id: number): Promise<void> => {
  const dto = mapUserSavePaymentToDto(payload);

  return http.put(`/user-payments/${id}`, dto);
};

/**
 * Delete user payment.
 */
export const deleteUserPayment = (id: number): Promise<void> => http.delete(`/user-payments/${id}`);

/**
 * Fetch user recurring payments.
 */
export const fetchReoccurringPayments = async (): Promise<PagedList<ReoccurringPayment>> => {
  const { data } = await http.get<ResponseDto<ReoccurringPaymentResponseDto>>('/reoccurring-payments');

  return mapDtoToReoccurringPayments(data);
};

/**
 * Fetch user recurring payment.
 */
export const fetchReoccurringPaymentById = async (id: number): Promise<ReoccurringPayment> => {
  const { data } = await http.get<ReoccurringPaymentResponseDto>(`/reoccurring-payments/${id}`);

  return mapDtoToReoccurringPayment(data);
};

/**
 * Delete user recurring payment.
 */
export const deleteReoccurringPayment = (id: number): Promise<void> => http.delete(`/reoccurring-payments/${id}`);

/**
 * Update user recurring payment.
 */
export const updateReoccurringPayment = (payload: ReoccurringPayment, id: number): Promise<void> => {
  const dto = mapReoccurringPaymentToDto(payload);

  return http.put(`/reoccurring-payments/${id}`, dto);
};
