import Pagination from 'models/Pagination';

/**
 * Pagination list.
 */
export default class PagedList<T> {
  /**
   * Pagination.
   */
  public readonly pagination?: Pagination;

  /**
   * Results.
   */
  public readonly results: T[];

  public constructor(data: PagedList<T>) {
    this.pagination = data.pagination;
    this.results = data.results;
  }
}
