import * as Yup from 'yup';

export interface ResetPassword {
  email: string;
  organizationId: number;
}

export const initialFormState = (id: number): ResetPassword => ({
  email: '',
  organizationId: id,
});

export const formValidation: Yup.SchemaOf<Omit<ResetPassword, 'organizationId'>> = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Enter an email'),
});
