import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface CharityItemProps extends SvgIconProps {
  /**
   * Applies a color attribute to the SVG element.
   */
  htmlColor?: string;
}

/* eslint-disable max-len */
export const HeartIcon = ({ htmlColor, ...props }: CharityItemProps): JSX.Element => (
  <SvgIcon {...props} width="22px" height="22px" viewBox="0 0 22 22">
    <g id="ELEMENT-SHEET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-44.000000, -393.000000)" fill={htmlColor} id="icons/donation-only">
        <g transform="translate(44.000000, 393.000000)">
          <path d="M19.4835027,4.50584412 C18.4395663,3.37359237 17.0071348,2.75 15.4497693,2.75 C14.2856687,2.75 13.2195765,3.11803182 12.2810339,3.84378687 C11.8074544,4.21012622 11.3783404,4.65831885 11,5.181441 C10.6218135,4.6584727 10.1925456,4.21012622 9.7188123,3.84378687 C8.7804235,3.11803182 7.7143313,2.75 6.55023067,2.75 C4.99286523,2.75 3.56027987,3.37359237 2.51634342,4.50584412 C1.48486963,5.62486393 0.916666667,7.15361149 0.916666667,8.81067786 C0.916666667,10.5162099 1.55226009,12.0774218 2.9168396,13.7240257 C4.13756055,15.1969223 5.8920199,16.6921285 7.92373405,18.423509 C8.61748633,19.0147909 9.40386198,19.6850026 10.2203941,20.3989105 C10.4361051,20.5878499 10.7128983,20.6918589 11,20.6918589 C11.2869479,20.6918589 11.5638949,20.5878499 11.7792981,20.3992183 C12.5958303,19.6851565 13.3826676,19.014637 14.0767275,18.4230474 C16.108134,16.6919746 17.8625933,15.1969223 19.0833142,13.7238719 C20.4478938,12.0774218 21.0833333,10.5162099 21.0833333,8.81052401 C21.0833333,7.15361149 20.5151304,5.62486393 19.4835027,4.50584412 Z" id="Path" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

HeartIcon.defaultProps = {
  htmlColor: '#fff',
};
