import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from 'notistack';

import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { deleteUserPaymentById, getUserPaymentById, getUserPayments } from 'store/UserPayment';
import {
  AppConfirmationModal, ERROR, Loading, SUCCESS,
} from 'components';
import { PaymentMethod } from 'models/enums';
import { PaymentCreditForm } from 'features/user/components/PaymentCreditForm';
import { PaymentAchForm } from 'features/user/components/PaymentAchForm';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import styles from './PaymentEditPage.module.css';

const PaymentEditPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { details, error, removing } = useAppSelector((state) => state.userPayment);

  const [isOpenDeletePayment, setOpenDeletePayment] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch(getUserPaymentById(Number(params.id)));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (error) {
      snackbar.enqueueSnackbar(error, ERROR);
    }
  }, [error, snackbar]);

  /**
   * It's opening and closing DeletePayment pop up.
   */
  const updateDeletePaymentModal = useCallback((isOpen: boolean) => (): void => {
    setOpenDeletePayment(isOpen);
  }, []);

  const paymentId = Number(params.id) ?? null;
  const pathPaymentInfo = `/${params.slug}/sidebar/payment`;
  const paymentForm = details?.paymentMethod === PaymentMethod.Card
    ? (
      <PaymentCreditForm
        path={pathPaymentInfo}
        paymentId={paymentId}
        onDeletePayment={updateDeletePaymentModal(true)}
      />
    )
    : (
      <PaymentAchForm
        path={pathPaymentInfo}
        paymentId={paymentId}
        onDeletePayment={updateDeletePaymentModal(true)}
      />
    );

  /**
   * It's deleting payment method.
   */
  const handleDeletePayment = useCallback(async (): Promise<void> => {
    updateDeletePaymentModal(false);

    await dispatch(deleteUserPaymentById(paymentId)).unwrap();
    snackbar.enqueueSnackbar('This payment method has been deleted successfully', SUCCESS);

    dispatch(getUserPayments());
    navigate(pathPaymentInfo);
  }, [dispatch, navigate, pathPaymentInfo, paymentId, snackbar, updateDeletePaymentModal]);

  if (error && !details) {
    return <Navigate to={pathPaymentInfo} replace />;
  }

  return (
    <>
      <Helmet>
        <title>Payment Edit Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="Edit Payment Method" />
        {!details ? <Loading /> : paymentForm}
      </div>
      <AppConfirmationModal
        header="Are you sure?"
        buttonText="Delete saved payment method"
        message={`Are you sure you want to delete this payment method?
          This action cannot be undone.`}
        processing={removing}
        open={isOpenDeletePayment}
        onConfirm={handleDeletePayment}
        onCloseModal={updateDeletePaymentModal(false)}
      />
    </>
  );
};

export default PaymentEditPage;
