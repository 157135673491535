import { ResetPasswordDto } from 'api/dtos/authDto';
import { ResetPassword } from 'features/auth/components/ForgotPasswordForm/formSettings';

/**
 * Map Reset Password model to DTO.
 */
export const mapResetPasswordToDto = (payload: ResetPassword): ResetPasswordDto => ({
  email: payload.email,
  organization_id: payload.organizationId,
});
