import React, {
  useState, memo, ChangeEvent, MouseEvent,
} from 'react';
import { useField, useFormikContext } from 'formik';
import {
  IconButton, InputAdornment, OutlinedInput, OutlinedInputProps, FormLabel, FormControl, FormHelperText,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import styles from './BaseInputPassword.module.css';

interface BaseInputPasswordProps extends OutlinedInputProps {
  /**
   * Field name.
   */
  name: string;
  /**
   * Check field is required.
   */
  required?: boolean;
}

interface State {
  password: string;
  showPassword: boolean;
}

const BaseInputPasswordComponent = ({
  id, label, name, required, ...props
}: BaseInputPasswordProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const configFormControl = {
    fullWidth: true,
    error: false,
  };

  let helperText: string | null = null;

  const configTextField = {
    ...field,
    ...props,
    id,
    fullWidth: true,
  };

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValues({ ...values, [prop]: value });
    setFieldValue(name, value);
  };

  const handleTogglePassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl {...configFormControl} className={styles.host} required={required}>
      <FormLabel className={styles.label} htmlFor={id}>{label}</FormLabel>
      <OutlinedInput
        {...configTextField}
        className={styles.input}
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange('password')}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleTogglePassword}
              onMouseDown={handleMouseDownPassword}
              className="inputIcon"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseInputPasswordComponent.defaultProps = {
  required: false,
};

export const BaseInputPassword = memo(BaseInputPasswordComponent);
