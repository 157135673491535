import * as Yup from 'yup';

import UserPayment from 'models/UserPayment';
import { getLast4 } from 'utils/getLast4';
import { UserSavePaymentControl } from 'models/UserSavePayment';

export interface PaymentFormState extends Pick<UserSavePaymentControl, 'name' | 'isDefaultPayment' > {
  achName: string;
  accountNumber: string;
  routingNumber: string;
  token: string;
  expiry: string;
}

export const initialFormState = (paymentInfo: UserPayment | null): PaymentFormState => ({
  name: paymentInfo?.name ?? '',
  accountNumber: paymentInfo?.last4 ? getLast4(paymentInfo?.last4 ?? '') : '',
  routingNumber: '',
  achName: paymentInfo?.achName ?? '',
  token: paymentInfo?.token ?? '',
  expiry: paymentInfo?.expiry ?? '',
  isDefaultPayment: paymentInfo?.isDefaultPayment ?? false,
});

export const formValidation: Yup.SchemaOf<Omit<
  PaymentFormState, 'isDefaultPayment'|'expiry'|'token'
>> = Yup.object().shape({
  name: Yup.string().trim().required('Enter a name'),
  accountNumber: Yup.string().min(8, 'Account number is invalid')
    .max(12, 'Max size 12 characters')
    .required('Enter an account number')
    .test(
      'is-integer',
      'Invalid number',
      (number) => /^([0-9]\d*)?$/.test(number as string),
    ),
  routingNumber: Yup.string().min(8, 'Routing number is invalid')
    .max(12, 'Max size 12 characters')
    .required('Enter a routing number')
    .test(
      'is-integer',
      'Invalid number',
      (number) => /^([0-9]\d*)?$/.test(number as string),
    ),
  achName: Yup.string()
    .max(100, 'Max size 100 characters')
    .required('Enter an account holder name'),
});
