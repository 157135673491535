import download from 'downloadjs';

import SortParams from 'models/QueryParams';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';
import { http } from '../index';

/**
 * Export to file.
 */
export const exportToFile = async (queryParams: SortParams, endpointPath: string): Promise<void> => {
  try {
    const dtoParams = mapQueryParamsToDto(queryParams);
    const { data, headers } = await http.get(endpointPath, {
      params: { ...dtoParams, download: true },
      responseType: 'blob',
    });

    download(data, headers['x-suggested-filename'], data.type);
  } catch (error) {
    // If responseType is blob, error response is converted into a blob as well.
    // This is a feature of Axios - return the same type in the response as in the request.
    // You can handle it with interceptors: "https://github.com/axios/axios/issues/815"
    throw new Error('File not found');
  }
};
