import { createSlice } from '@reduxjs/toolkit';

import { exportToFile } from 'store/Utils/dispatchers';
import { state } from 'store/Utils/state';

const utilsSlice = createSlice({
  name: 'utils',
  initialState: state,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(exportToFile.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(exportToFile.fulfilled, (state) => {
      state.processing = false;
    });
    builder.addCase(exportToFile.rejected, (state, { error }) => {
      state.error = error.message ?? '';
      state.processing = false;
    });
  },
});

export const utils = utilsSlice.reducer;
