import { AxiosResponse } from 'axios';

import {
  AuthDto, ConfirmResetPasswordDto, LoginDto, ResetPasswordDto, UpdatePasswordDto,
} from 'api/dtos/authDto';
import { setSessionToken, isRememberMe } from 'api/services/Session';
import {
  mapDtoToLogin,
  mapLoginToDto,
  mapConfirmResetPasswordToDto,
} from 'api/mappers/authMapper';
import { ConfirmResetPassword, Token } from 'models/Auth';
import { ResetPassword } from 'features/auth/components/ForgotPasswordForm/formSettings';
import { mapResetPasswordToDto } from 'api/mappers/resetPasswordMapper';
import { http } from '../index';

/**
 * Login.
 */
export const createSession = async (payload: LoginDto): Promise<AuthDto> => {
  try {
    const dto = mapLoginToDto(payload);
    const { data } = await http.post<AuthDto>('/auth', dto);

    setSessionToken(data.token);

    return mapDtoToLogin(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Refresh token.
 */
export const updateSession = async (token: string): Promise<Token> => {
  try {
    const { data } = await http.put<AuthDto>('/auth', token);

    setSessionToken(data.token, isRememberMe());

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Logout.
 */
export const deleteSession = async (): Promise<AxiosResponse> => {
  try {
    const result = await http.delete('/auth');

    setSessionToken();

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Reset password.
 */
export const resetPassword = async (payload: ResetPassword): Promise<AxiosResponse> => {
  try {
    const dto = mapResetPasswordToDto(payload);

    return await http.post<ResetPasswordDto>('/auth/password/reset', dto);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Confirm reset password.
 */
export const confirmResetPassword = async (payload: ConfirmResetPassword): Promise<AxiosResponse> => {
  try {
    const dto = mapConfirmResetPasswordToDto(payload);

    return await http.put<ConfirmResetPasswordDto>('/auth/password/reset', dto);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Set new password.
 */
export const setNewPassword = (payload: { oldPassword: string, newPassword: string }): Promise<void> => {
  const dto: UpdatePasswordDto = {
    old_password: payload.oldPassword,
    new_password: payload.newPassword,
  };

  return http.put('/auth/password', dto);
};
