import UserEditDto from 'api/dtos/userEditDto';
import UserEdit from 'models/UserEdit';

/**
 * Map UserEdit model to DTO.
 */
export const mapUserEditToDto = (payload: UserEdit): UserEditDto => ({
  first_name: payload.firstName,
  last_name: payload.lastName,
  email: payload.email,
  phone: payload.phone,
  organization_id: payload.organizationId,
});
