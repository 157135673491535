import React, { memo, MouseEvent } from 'react';
import {
  Box,
  DialogProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import check from 'assets/images/check.svg';

import styles from './SignUpCompletedModal.module.css';

const SignUpCompletedModalComponent = ({ open, onClose, ...props }: DialogProps): JSX.Element => {
  const handleClosePrevent = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      if (reason !== 'backdropClick') {
        onClose(event, reason);
      }
    }
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    // onClose is optional props. Need to pass event and reason 'escapeKeyDown' for closing by Esc.
    if (onClose) {
      onClose(event, 'escapeKeyDown');
    }
  };

  return (
    <Dialog
      {...props}
      open={open}
      aria-labelledby="alert-dialog-title"
      onClose={(event, reason) => handleClosePrevent(event, reason)}
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center" paddingBottom="48px">
          <img src={check} alt="Check" className={styles.checkIcon} />
          <Typography variant="h4" className={styles.h4}>Thank you for registering!</Typography>
          <Typography variant="body2">
            Please check your inbox for a confirmation email.
            Click the link in the email to verify your account and complete the registration process.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export const SignUpCompletedModal = memo(SignUpCompletedModalComponent);
