import React, { memo, useState, MouseEvent } from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import {
  FormHelperText, FormLabel, FormControl, Tooltip,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { DayOfWeek } from 'models/enums';
import { DAYS } from 'utils/date';

import styles from './DayOfWeekPicker.module.css';

interface DayOfWeekPickerProps {
  /**
   * Text label.
   */
  label: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Initial value of DayOfWeekPicker.
   */
  initValue?: DayOfWeek;
}

const DayOfWeekPickerComponent = ({ name, label, initValue }: DayOfWeekPickerProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);

  const initDay = initValue ?? null;

  const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek | null>(initDay);

  const handleDayOfWeekSelect = (event: MouseEvent<HTMLElement>, day: DayOfWeek | null) => {
    if (day) {
      setDayOfWeek(day);
      setFieldValue(field.name, day);
    }
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    helperText = meta.error;
  }

  return (
    <FormControl className={styles.host}>
      <FormLabel className={styles.label}>{label}</FormLabel>
      <ToggleButtonGroup
        value={dayOfWeek}
        exclusive
        onChange={handleDayOfWeekSelect}
        aria-label="repeat payment"
      >
        {DAYS.map((value) => (
          <ToggleButton value={value} aria-label={value} key={value}>
            <Tooltip title={value}><span>{value.charAt(0)}</span></Tooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

DayOfWeekPickerComponent.defaultProps = {
  initValue: null,
};

export const DayOfWeekPicker = memo(DayOfWeekPickerComponent);
