import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import {
  fetchUser, updateUser, createUser, fetchUserTransactions,
} from 'api/services/User';
import UserEdit from 'models/UserEdit';
import Register from 'models/Register';
import SortParams from 'models/QueryParams';

import { AppThunk } from '../index';
import {
  setUserStart,
  setUserSuccess,
  setUserFailed,
  setUserUpdateStart,
  setUserUpdateSuccess,
  setUserUpdateFailed,
} from './userSlice';

/**
 * Get user.
 */
export const getUser = (): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setUserStart());
    const user = await fetchUser();

    dispatch(setUserSuccess(user));
  } catch (error) {
    dispatch(setUserFailed(error as Error));

    throw error;
  }
};

/**
 * Update user's info.
 */
export const update = (data: UserEdit): AppThunk => async (dispatch: Dispatch) => {
  try {
    dispatch(setUserUpdateStart());

    const user = await updateUser(data);

    dispatch(setUserUpdateSuccess(user));
  } catch (error) {
    dispatch(setUserUpdateFailed(error as Error));

    throw error;
  }
};

/**
 * Register a new user.
 */
export const create = createAsyncThunk(
  'user/register',
  async (data: Register, { rejectWithValue }) => {
    try {
      return await createUser(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Get paginated list of user transactions.
 */
export const getUserTransactions = createAsyncThunk(
  'user/getTransactions',
  ({ params = {} }: { params?: SortParams }) => fetchUserTransactions(params),
);
