import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

/* eslint-disable max-len */
export const CheckIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} width="20px" height="20px" viewBox="0 0 20 20">
    <g id="ELEMENT-SHEET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-879.000000, -434.000000)">
        <g id="icons/check-success" transform="translate(879.000000, 434.000000)" fill="#1EA757">
          <g id="Icons-/-checkmark-/-circle-/-fill">
            <path d="M10,0 C4.48605769,0 0,4.48605769 0,10 C0,15.5139423 4.48605769,20 10,20 C15.5139423,20 20,15.5139423 20,10 C20,4.48605769 15.5139423,0 10,0 Z M15.2043269,6.64855769 L8.74278846,14.3408654 C8.59937385,14.5116844 8.38887745,14.6118457 8.16586538,14.6153846 L8.15288462,14.6153846 C7.93483778,14.6153846 7.72706882,14.522692 7.58125,14.3605769 L4.81201923,11.2836538 C4.620572,11.0805977 4.55411957,10.7897786 4.63837465,10.5237242 C4.72262972,10.2576699 4.94437461,10.0581181 5.21780869,10.0022823 C5.49124277,9.94644651 5.77347053,10.043086 5.95528846,10.2548077 L8.13269231,12.6740385 L14.0264423,5.65913462 C14.301528,5.34109396 14.7810215,5.30288565 15.103002,5.57334926 C15.4249825,5.84381287 15.470112,6.32270455 15.2043269,6.64855769 Z" id="Icon" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
