/**
 *  Banner model class.
 */
export default class Banner {
  /**
   * Unique Id.
   */
  public readonly id: number;

  /**
   * File Url.
   */
  public readonly filePath: string;

  constructor(data: Banner) {
    this.id = data.id;
    this.filePath = data.filePath;
  }
}
