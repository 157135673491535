import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Organization from 'models/Organization';
import { getOrganizationBySlug, makeDonation } from './dispatchers';
import { state } from './state';

const organizationSlice = createSlice({
  name: 'organization',
  initialState: state,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOrganizationBySlug.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrganizationBySlug.fulfilled, (state, { payload }: PayloadAction<Organization>) => {
      state.data = payload;
      state.loading = false;
    });
    builder.addCase(getOrganizationBySlug.rejected, (state, { error }) => {
      state.error = error.message ?? null;
      state.loading = false;
    });
    builder.addCase(makeDonation.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(makeDonation.fulfilled, (state) => {
      state.processing = false;
    });
    builder.addCase(makeDonation.rejected, (state, { error }) => {
      state.error = error.message ?? null;
      state.processing = false;
    });
  },
});

export const organization = organizationSlice.reducer;
