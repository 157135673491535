import React, { memo, useEffect, useState } from 'react';
import { GridSortModel } from '@material-ui/data-grid';
import { Divider } from '@material-ui/core';

import { BaseDataTable, ExportButton } from 'components';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { getUserTransactions } from 'store/User';
import SortParams from 'models/QueryParams';
import { SortOrder } from 'models/enums/SortOrder';
import { columns } from './tableSettings';

import styles from './TransactionsHistory.module.css';

const TransactionsHistoryComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state) => state.user.transactions);

  const [queryParams, setQueryParams] = useState<SortParams>(
    { orderBy: 'createdAt', sortOrder: SortOrder.Desc },
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'createdAt', sort: SortOrder.Desc },
  ]);

  useEffect(() => {
    dispatch(getUserTransactions({ params: queryParams }));
  }, [dispatch, queryParams]);

  const handleSortModelChange = (param: GridSortModel) => {
    setSortModel(param);
    if (param.length === 0) {
      return;
    }

    // Update sort model only when param was changed.
    if (JSON.stringify(param) === JSON.stringify([{ field: queryParams.orderBy, sort: queryParams.sortOrder }])) {
      return;
    }

    const { sort, field } = param[0];

    setQueryParams({
      ...queryParams,
      orderBy: field,
      sortOrder: sort as SortOrder,
    });
  };

  const rows = data?.results;

  return (
    <>
      <BaseDataTable
        rows={rows || []}
        columns={columns}
        loading={loading}
        rowHeight={42}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
        hideFooter
        zebra
      />
      <Divider />
      <div className={styles.buttonWrapper}>
        <ExportButton
          className={styles.buttonTransactions}
          endpointPath="users/me/transactions"
          queryParams={queryParams}
          disabled={rows?.length === 0}
          fullWidth
        />
      </div>
    </>
  );
};

export const TransactionsHistory = memo(TransactionsHistoryComponent);
