import Organization from 'models/Organization';

interface OrganizationState {
  loading: boolean;
  processing: boolean;
  data: Organization | null;
  error: string | null;
}

export const state: OrganizationState = {
  loading: false,
  processing: false,
  data: null,
  error: null,
};
