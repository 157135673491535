import UserPayment from 'models/UserPayment';
import PagedList from 'models/PagedList';
import ReoccurringPayment from 'models/ReoccurringPayment';

interface UserPaymentState {
  loading: boolean;
  processing: boolean;
  removing: boolean;
  list: PagedList<UserPayment> | null;
  details: UserPayment | null;
  error: string | null;
  reoccurring: {
    loading: boolean;
    removing: boolean;
    list: PagedList<ReoccurringPayment> | null;
    details: ReoccurringPayment | null;
    error: string | null;
  },
}

export const state: UserPaymentState = {
  loading: false,
  processing: false,
  removing: false,
  list: null,
  details: null,
  error: null,
  reoccurring: {
    loading: false,
    removing: false,
    list: null,
    details: null,
    error: null,
  },
};
