import { createAsyncThunk } from '@reduxjs/toolkit';

import { exportToFile as exportReportToFile } from 'api/services/Utils';
import SortParams from 'models/QueryParams';

type Export = {
  queryParams: SortParams;
  endpointPath: string;
};

/**
 * Export into file.
 */
export const exportToFile = createAsyncThunk(
  'utils/export',
  ({ queryParams, endpointPath }: Export): Promise<void> => exportReportToFile(queryParams, endpointPath),
);
