import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

/* eslint-disable max-len */
export const DollarIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} width="22px" height="22px" viewBox="0 0 22 22">
    <g id="ELEMENT-SHEET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-116.000000, -393.000000)" fill="#FFFFFF" id="icons/fee-only">
        <g transform="translate(116.000000, 393.000000)">
          <path d="M11,21.0833333 C16.5458333,21.0833333 21.0833333,16.5458333 21.0833333,11 C21.0833333,5.45416667 16.5458333,0.916666667 11,0.916666667 C5.45416667,0.916666667 0.916666667,5.45416667 0.916666667,11 C0.916666667,16.5458333 5.45416667,21.0833333 11,21.0833333 Z M11.0229167,12.4993056 C9.01041667,12.4993056 7.33333333,10.8222222 7.33333333,8.80972222 C7.33333333,7.13263889 8.45138889,5.79097222 9.90486111,5.34375 L9.90486111,4.78472222 C9.90486111,4.11388889 10.3520833,3.66666667 11.0229167,3.66666667 C11.69375,3.66666667 12.1409722,4.11388889 12.1409722,4.78472222 L12.1409722,5.23194444 C13.5944444,5.67916667 14.7125,7.13263889 14.7125,8.69791667 C14.7125,9.36875 14.2652778,9.81597222 13.5944444,9.81597222 C12.9236111,9.81597222 12.4763889,9.36875 12.4763889,8.69791667 C12.4763889,7.91527778 11.8055556,7.24444444 11.0229167,7.24444444 C10.2402778,7.24444444 9.56944444,7.91527778 9.56944444,8.69791667 C9.56944444,9.48055556 10.2402778,10.1513889 11.0229167,10.1513889 C13.0354167,10.1513889 14.7125,11.8284722 14.7125,13.8409722 C14.7125,15.5180556 13.5944444,16.8597222 12.1409722,17.3069444 L12.1409722,17.7541667 C12.1409722,18.3131944 11.69375,18.8722222 11.0229167,18.8722222 C10.3520833,18.8722222 9.90486111,18.3131944 9.90486111,17.7541667 L9.90486111,17.3069444 C8.45138889,16.8597222 7.33333333,15.40625 7.33333333,13.8409722 C7.33333333,13.2819444 7.78055556,12.7229167 8.45138889,12.7229167 C9.12222222,12.7229167 9.56944444,13.2819444 9.56944444,13.8409722 C9.56944444,14.6236111 10.2402778,15.2944444 11.0229167,15.2944444 C11.8055556,15.2944444 12.4763889,14.6236111 12.4763889,13.8409722 C12.4763889,13.1701389 11.8055556,12.4993056 11.0229167,12.4993056 Z" id="Shape" />
        </g>
      </g>
    </g>
  </SvgIcon>
);
