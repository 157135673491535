import UserPayment from 'models/UserPayment';
import UserPaymentDto from 'api/dtos/userPaymentDto';
import ResponseDto from 'api/dtos/responseDto';
import PagedList from 'models/PagedList';
import { PaymentMethod } from 'models/enums';
import { mapDtoToPagination } from './paginationMapper';

/**
 * Map User payment model to DTO.
 */
export const mapDtoToUserPayment = (dto: UserPaymentDto): UserPayment => new UserPayment({
  id: dto?.id,
  paymentMethod: dto.payment_method as PaymentMethod,
  last4: dto.last_4,
  name: dto.name,
  cardName: dto.card_name,
  achName: dto.ach_name,
  isDefaultPayment: dto.is_default_payment,
  userId: dto.user_id,
  receipt: dto.receipt,
  donateAs: dto.donate_as,
  token: dto.token,
  expiry: dto.expiry,
  zip: dto.zip,
});

/**
 * Map DTO to User payments list.
 */
export function mapDtoToUserPayments(dtos: ResponseDto<UserPaymentDto>): PagedList<UserPayment> {
  return new PagedList<UserPayment>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToUserPayment),
  });
}
