import axios, { Canceler } from 'axios';

import UserDto from 'api/dtos/userDto';
import { mapDtoToUser } from 'api/mappers/userMapper';
import { mapUserEditToDto } from 'api/mappers/userEditMapper';
import { mapRegisterToDto } from 'api/mappers/registerMapper';
import User from 'models/User';
import UserEdit from 'models/UserEdit';
import Register from 'models/Register';
import PagedList from 'models/PagedList';
import UserTransaction from 'models/UserTransaction';
import SortParams from 'models/QueryParams';
import UserTransactionDto from 'api/dtos/userTransactionDto';
import ResponseDto from 'api/dtos/responseDto';
import { mapDtoToUserTransactions } from 'api/mappers/userTransactionMapper';
import { mapQueryParamsToDto } from 'api/mappers/queryParamsMapper';
import { http } from '../index';

/**
 * Fetch user.
 */
export const fetchUser = async (): Promise<User> => {
  try {
    const { data } = await http.get<UserDto>('/users/me');

    return mapDtoToUser(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Update user's info.
 */
export const updateUser = async (payload: UserEdit): Promise<User> => {
  try {
    const dto = mapUserEditToDto(payload);
    const { data } = await http.put<UserDto>('/users/me', dto);

    return mapDtoToUser(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Create User.
 */
export const createUser = async (payload: Register): Promise<User> => {
  try {
    const dto = mapRegisterToDto(payload);
    const { data } = await http.post<UserDto>('/users', dto);

    return mapDtoToUser(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const { CancelToken } = axios;
let cancelRequest: Canceler;

/**
 * Fetch user transactions.
 */
export const fetchUserTransactions = async (params: SortParams): Promise<PagedList<UserTransaction>> => {
  try {
    if (cancelRequest) {
      cancelRequest();
    }

    const dtoParams = mapQueryParamsToDto(params);
    const { data } = await http.get<ResponseDto<UserTransactionDto>>(
      '/users/me/transactions',
      {
        params: dtoParams,
        cancelToken: new CancelToken((cancel) => {
          cancelRequest = cancel;
        }),
      },
    );

    return mapDtoToUserTransactions(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
