import React from 'react';
import { Container, Typography } from '@material-ui/core';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import styles from './PageNotFound.module.css';

const PageNotFound = (): JSX.Element => (
  <Container className={styles.host}>
    <div>
      <Typography variant="h2" className={styles.error404}>
        4
        <SentimentVeryDissatisfiedIcon className={styles.icon} />
        4
      </Typography>
      <Typography variant="h4">Sorry, page not found</Typography>
    </div>
  </Container>
);

export default PageNotFound;
