import * as Yup from 'yup';

import { Frequency, PaymentMethod } from 'models/enums';
import ReoccurringPayment from 'models/ReoccurringPayment';

export interface ReoccurringFormState {
  amount: number;
  donatedTo: string;
  frequencyName: string;
  frequencyId: Frequency;
  chargeAt: string;
  userPaymentId: number;
  token: string;
  expiry: string;
  paymentMethod: string;
  achName?: string;
  routingNumber?: string;
  accountNumber?: string;
  isSavedPaymentMethod: boolean;
  last4: string;
  cardName?: string;
  isFutureUse?: boolean
  zip?: string;
}

export const initialFormState = (reoccurringInfo: ReoccurringPayment | null): ReoccurringFormState => ({
  amount: reoccurringInfo?.amount ?? 0,
  donatedTo: reoccurringInfo?.donatedTo ?? '',
  frequencyName: reoccurringInfo?.paymentFrequency?.name ?? '',
  frequencyId: reoccurringInfo?.paymentFrequency?.id ?? 0,
  chargeAt: reoccurringInfo?.chargeAt ?? '',
  userPaymentId: reoccurringInfo?.userPaymentId ?? 0,
  token: reoccurringInfo?.token ?? '',
  expiry: reoccurringInfo?.expiry ?? '',
  paymentMethod: PaymentMethod.Card,
  isSavedPaymentMethod: true,
  last4: '',
  cardName: '',
  isFutureUse: false,
  zip: '',
});

export const formValidation: Yup.SchemaOf<Pick<ReoccurringFormState, 'amount'>> = Yup.object().shape({
  cardName: Yup.string()
    .when('paymentMethod', {
      is: PaymentMethod.Card,
      then: Yup.string()
        .max(100, 'Max size 100 characters')
        .notRequired(),
      otherwise: Yup.string().notRequired(),
    }),
  zip: Yup.string()
    .when('paymentMethod', {
      is: PaymentMethod.Card,
      then: Yup.string()
        .max(9, 'Max size 9 characters')
        .notRequired(),
      otherwise: Yup.string().notRequired(),
    }),
  amount: Yup.number()
    .moreThan(0, 'Enter a donation amount')
    .required('Enter a donation amount'),
  achName: Yup.string()
    .when('paymentMethod', {
      is: PaymentMethod.Ach,
      then: Yup.string()
        .max(100, 'Max size 100 characters')
        .required('Enter an account holder name'),
      otherwise: Yup.string().notRequired(),
    }),
  routingNumber: Yup.string()
    .when(['paymentMethod', 'isSavedPaymentMethod'], {
      is: (paymentMethod: string, isSavedPaymentMethod: boolean) => {
        if (isSavedPaymentMethod) {
          return false;
        }

        return paymentMethod === PaymentMethod.Ach;
      },
      then: Yup.string().min(8, 'Routing number is invalid')
        .max(12, 'Max size 12 characters')
        .required('Enter a routing number')
        .test(
          'is-integer',
          'Invalid number',
          (number) => /^([1-9]\d*)?$/.test(number as string),
        ),
      otherwise: Yup.string().notRequired(),
    }),
  accountNumber: Yup.string()
    .when(['paymentMethod', 'isSavedPaymentMethod'], {
      is: (paymentMethod: string, isSavedPaymentMethod: boolean) => {
        if (isSavedPaymentMethod) {
          return false;
        }

        return paymentMethod === PaymentMethod.Ach;
      },
      then: Yup.string().min(8, 'Account number is invalid')
        .max(12, 'Max size 12 characters')
        .required('Enter an account number')
        .test(
          'is-integer',
          'Invalid number',
          (number) => /^([1-9]\d*)?$/.test(number as string),
        ),
      otherwise: Yup.string().notRequired(),
    }),
  token: Yup.string()
    .when(['paymentMethod', 'isSavedPaymentMethod'], {
      is: (paymentMethod: string, isSavedPaymentMethod: boolean) => {
        if (isSavedPaymentMethod) {
          return false;
        }

        return paymentMethod === PaymentMethod.Card;
      },
      then: Yup.string().required('Enter a card'),
      otherwise: Yup.string().notRequired(),
    }),
});
