import React, { memo } from 'react';
import { useSnackbar } from 'notistack';
import { Formik, Form, FormikHelpers } from 'formik';
import { Grid } from '@material-ui/core';

import { BaseButton, BaseInput } from 'components';
import { ERROR, SUCCESS } from 'components/AppSnackbar';
import { ServerValidationError } from 'utils/exceptions';
import transformAPIErrors from 'utils/transformAPIErrors';
import { getValidationErrors } from 'utils/transformErrors';
import { resetPassword } from 'store/Auth';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { formValidation, initialFormState, ResetPassword } from './formSettings';

import styles from './ForgotPasswordForm.module.css';

interface ForgotPasswordFormProps {
  /**
   * Method for to go to Login dialog.
   */
  onLogin: () => void;
  /**
   * Method for close Login dialog in case of successful submit .
   */
  onSuccess: () => void;
}

const ForgotPasswordFormComponent = ({ onLogin, onSuccess }: ForgotPasswordFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const { loading } = useAppSelector((state) => state.auth);
  const { data } = useAppSelector((state) => state.organization);

  const handleSubmit = async (values: ResetPassword, { setErrors }: FormikHelpers<ResetPassword>) => {
    try {
      await dispatch(resetPassword(values));

      snackbar.enqueueSnackbar('We have e-mailed your password reset link!', SUCCESS);
      onSuccess();
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      if (error instanceof ServerValidationError) {
        setErrors(getValidationErrors(serverErrors.errors));
      } else {
        snackbar.enqueueSnackbar(serverErrors.message, ERROR);
      }
    }
  };

  const formInitValues = initialFormState(data?.id ?? 0);

  return (
    <Formik
      initialValues={formInitValues}
      onSubmit={handleSubmit}
      validationSchema={formValidation}
      validateOnBlur={false}
    >
      <Form noValidate className="app-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BaseInput
              label="Email"
              id="email"
              name="email"
              type="email"
              required
            />
            <BaseButton color="primary" className={styles.buttonRedirect} onClick={onLogin}>Login</BaseButton>
          </Grid>
          <Grid item xs={12}>
            <BaseButton
              color="primary"
              type="submit"
              variant="contained"
              disableElevation
              fullWidth
              loading={loading}
              className={styles.button}
            >
              Reset Password
            </BaseButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export const ForgotPasswordForm = memo(ForgotPasswordFormComponent);
