import { createSlice } from '@reduxjs/toolkit';

import {
  createUserPayment,
  deleteReoccurringPaymentById,
  deleteUserPaymentById,
  getReoccurringPaymentById,
  getReoccurringPayments,
  getUserPaymentById,
  getUserPayments,
  resetReoccurringPayment,
  resetUserPayment,
  updateReoccurringPaymentById,
  updateUserPaymentById,
} from 'store/UserPayment/dispatchers';

import { state as initialState } from './state';

const userPaymentSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserPayments.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUserPayments.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.loading = false;
    });
    builder.addCase(getUserPayments.rejected, (state, { error }) => {
      state.error = error.message ?? '';
      state.loading = false;
    });
    builder.addCase(getUserPaymentById.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(getUserPaymentById.fulfilled, (state, { payload }) => {
      state.details = payload;
      state.processing = false;
    });
    builder.addCase(getUserPaymentById.rejected, (state, { error }) => {
      state.error = error.message ?? '';
      state.processing = false;
    });
    builder.addCase(updateUserPaymentById.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(updateUserPaymentById.fulfilled, (state) => {
      state.processing = false;
    });
    builder.addCase(updateUserPaymentById.rejected, (state, { payload }) => {
      state.error = payload?.message ?? '';
      state.processing = false;
    });
    builder.addCase(deleteUserPaymentById.pending, (state) => {
      state.removing = true;
      state.error = null;
    });
    builder.addCase(deleteUserPaymentById.fulfilled, (state) => {
      state.removing = false;
      state.details = initialState.details;
    });
    builder.addCase(deleteUserPaymentById.rejected, (state, { error }) => {
      state.error = error?.message ?? '';
      state.removing = false;
    });
    builder.addCase(createUserPayment.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(createUserPayment.fulfilled, (state) => {
      state.processing = false;
    });
    builder.addCase(createUserPayment.rejected, (state, { payload }) => {
      state.error = payload?.message ?? null;
      state.processing = false;
    });
    builder.addCase(resetUserPayment, (state) => {
      state.details = initialState.details;
    });
    builder.addCase(getReoccurringPayments.pending, (state) => {
      state.reoccurring.loading = true;
      state.reoccurring.error = null;
    });
    builder.addCase(getReoccurringPayments.fulfilled, (state, { payload }) => {
      state.reoccurring.list = payload;
      state.reoccurring.loading = false;
    });
    builder.addCase(getReoccurringPayments.rejected, (state, { error }) => {
      state.reoccurring.error = error.message ?? '';
      state.reoccurring.loading = false;
    });
    builder.addCase(getReoccurringPaymentById.pending, (state) => {
      state.reoccurring.loading = true;
      state.reoccurring.error = null;
    });
    builder.addCase(getReoccurringPaymentById.fulfilled, (state, { payload }) => {
      state.reoccurring.details = payload;
      state.reoccurring.loading = false;
    });
    builder.addCase(getReoccurringPaymentById.rejected, (state, { error }) => {
      state.reoccurring.error = error.message ?? '';
      state.reoccurring.loading = false;
    });
    builder.addCase(resetReoccurringPayment, (state) => {
      state.reoccurring.details = initialState.reoccurring.details;
    });
    builder.addCase(deleteReoccurringPaymentById.pending, (state) => {
      state.reoccurring.removing = true;
      state.reoccurring.error = null;
    });
    builder.addCase(deleteReoccurringPaymentById.fulfilled, (state) => {
      state.reoccurring.removing = false;
      state.reoccurring.details = initialState.reoccurring.details;
    });
    builder.addCase(deleteReoccurringPaymentById.rejected, (state, { error }) => {
      state.reoccurring.error = error?.message ?? '';
      state.reoccurring.removing = false;
    });
    builder.addCase(updateReoccurringPaymentById.pending, (state) => {
      state.reoccurring.loading = true;
      state.reoccurring.error = null;
    });
    builder.addCase(updateReoccurringPaymentById.fulfilled, (state) => {
      state.reoccurring.loading = false;
    });
    builder.addCase(updateReoccurringPaymentById.rejected, (state, { payload }) => {
      state.reoccurring.error = payload?.message ?? '';
      state.reoccurring.loading = false;
    });
  },
});

export const userPayment = userPaymentSlice.reducer;
