import { Frequency } from './enums';
import PaymentFrequency from './PaymentFrequency';
import UserPayment from './UserPayment';

/**
 *  ReoccurringPayment model class.
 */
export default class ReoccurringPayment {
  /**
   * Unique Id.
   */
  public readonly id?: number;

  /**
   * Amount.
   */
  public readonly amount: number;

  /**
   * User payment id.
   */
  public readonly userPaymentId: number;

  /**
   * User payment.
   */
  public readonly userPayment?: UserPayment;

  /**
   * Organization donated to name.
   */
  public readonly donatedTo: string;

  /**
   * Cardpointe token.
   */
  public readonly token: string;

  /**
   * Cardpointe expiry.
   */
  public readonly expiry: string;

  /**
   * Payment method.
   */
  public readonly paymentMethod: string;

  /**
   * Cardholder name.
   */
  public readonly cardName?: string | null;

  /**
   * Is future use.
   */
  public readonly isFutureUse?: boolean | null;

  /**
   * Last 4 numbers of a card number(account).
   */
  public readonly last4: string;

  /**
   * Frequency.
   */
  public readonly frequency: Frequency;

  /**
   * Payment frequency.
   */
  public readonly paymentFrequency?: PaymentFrequency;

  /**
   * Charge at.
   */
  public readonly chargeAt: string;

  /**
   * ZIP.
   */
  public readonly zip?: string | null;

  constructor(data: ReoccurringPayment) {
    this.id = data.id;
    this.amount = data.amount;
    this.userPaymentId = data.userPaymentId;
    this.userPayment = data.userPayment;
    this.donatedTo = data.donatedTo;
    this.token = data.token;
    this.expiry = data.expiry;
    this.paymentMethod = data.paymentMethod;
    this.cardName = data.cardName;
    this.isFutureUse = data.isFutureUse;
    this.last4 = data.last4;
    this.frequency = data.frequency;
    this.paymentFrequency = data.paymentFrequency;
    this.chargeAt = data.chargeAt;
    this.zip = data.zip;
  }
}
