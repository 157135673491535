import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { userRoutes } from 'features/user/routes';

const DonationPage = lazy(() => import('./pages/DonationPage/DonationPage'));
// TODO: need to remove, this page only for tests
const TmpIFramePage = lazy(() => import('./pages/TmpIFramePage/TmpIFramePage'));
const PageNotFound = lazy(() => import('components/PageNotFound/PageNotFound'));

export const donationRoutes = [
  {
    path: '',
    children: [
      {
        path: '/',
        element: <PageNotFound />,
      },
      {
        path: ':slug',
        element: <Outlet />,
        children: [
          {
            path: 'iframe',
            element: <TmpIFramePage />,
          },
          {
            path: '',
            element: <DonationPage />,
            children: [
              {
                path: 'sidebar',
                element: <Outlet />,
                children: [
                  ...userRoutes,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
