import React, { memo } from 'react';
import {
  Box,
  DialogProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { BaseButton } from 'components';

import styles from './AppConfirmationModal.module.css';

interface AppConfirmationModalProps extends DialogProps {
  /**
   * Header pop up.
   */
  header: string;
  /**
   * Text button pop up.
   */
  buttonText: string;
  /**
   * Message pop up.
   */
  message: string;
  /**
   * Check if action pop up is in progress.
   */
  processing: boolean;
  /**
   * Method to perform if action pop up is confirmed.
   */
  onConfirm: () => void;
  /**
   * Method for closing pop up.
   */
  onCloseModal: () => void;
}

const AppConfirmationModalComponent = ({
  header, buttonText, message, processing, open, onConfirm, onCloseModal, ...props
}: AppConfirmationModalProps): JSX.Element => {
  /**
   * It's closing item checking if the click is outside the this item.
   */
  const preventBackdropClick = (event: object, reason: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  /**
   * It's closing pop up.
   */
  const handleClose = (): void => {
    onCloseModal();
  };

  /**
   * It's stopping recurring payment.
   */

  return (
    <Dialog
      {...props}
      open={open}
      aria-labelledby="alert-dialog-title"
      onClose={preventBackdropClick}
      disableEscapeKeyDown
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div className={styles.container}>
          <Box textAlign="center" paddingBottom={2}>
            <Typography variant="h4" className={styles.heading}>{header}</Typography>
            <Typography variant="body2" className={styles.paragraph}>{message}</Typography>
          </Box>
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <div className={styles.container}>
          <BaseButton
            className={styles.button}
            color="secondary"
            onClick={onConfirm}
            size="large"
            loading={processing}
            variant="contained"
            fullWidth
            disableElevation
          >
            {buttonText}
          </BaseButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export const AppConfirmationModal = memo(AppConfirmationModalComponent);
