import Banner from 'models/Banner';
import BannerDto from 'api/dtos/bannerDto';

/**
 * Map DTO to Banner model.
 */
export const mapDtoToBanner = (dto: BannerDto): Banner => new Banner({
  id: dto.id,
  filePath: dto.file_path,
});
