import PaymentFrequency from 'models/PaymentFrequency';
import PagedList from 'models/PagedList';

interface SiteState {
  paymentFrequency: {
    loading: boolean;
    data: PagedList<PaymentFrequency> | null;
    error: string | null;
  },
}

export const state: SiteState = {
  paymentFrequency: {
    loading: false,
    data: null,
    error: null,
  },
};
