import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Form, FormikHelpers, useFormik, FormikProvider,
} from 'formik';
import {
  Box, Divider, Grid, Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  BaseButton, BaseButtonLink, BaseCheckbox, BaseInput, PaymentCard,
} from 'components';
import { ERROR, SUCCESS } from 'components/AppSnackbar';
import ApiError from 'models/ApiError';
import { getValidationErrors } from 'utils/transformErrors';
import { useAppSelector, useAppDispatch } from 'store/rootReducer';
import { createUserPayment, getUserPayments, updateUserPaymentById } from 'store/UserPayment';
import { PaymentMethod } from 'models/enums';
import { UserSavePaymentControl } from 'models/UserSavePayment';
import { formValidation, initialFormState, PaymentFormState } from './formSettings';

import styles from './PaymentCreditForm.module.css';

interface PaymentCreditFormProps {
  /**
   * Path.
   */
  path: string;
  /**
   * Payment id.
   */
  paymentId?: number;
  /**
   * Method for opening DeletePayment pop up.
   */
  onDeletePayment?: () => void;
}

const PaymentCreditFormComponent = ({
  path, paymentId, onDeletePayment: onOpenDeletePaymentModal,
}: PaymentCreditFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { details, processing } = useAppSelector((state) => state.userPayment);

  const isEditMode = Boolean(paymentId);

  const handleSubmit = async (values: PaymentFormState, { setErrors }: FormikHelpers<PaymentFormState>) => {
    try {
      const payment: UserSavePaymentControl = {
        ...values,
        paymentMethod: details?.paymentMethod ?? PaymentMethod.Card,
      };

      if (isEditMode && paymentId) {
        await dispatch(updateUserPaymentById({ payment, id: paymentId })).unwrap();

        snackbar.enqueueSnackbar('Your payment has been changed successfully', SUCCESS);
      } else {
        await dispatch(createUserPayment({ payment })).unwrap();

        snackbar.enqueueSnackbar('Your payment has been created successfully', SUCCESS);
      }

      await dispatch(getUserPayments());
      navigate(path);
    } catch (error) {
      const apiError = error as ApiError;

      if (apiError.errors) {
        setErrors(getValidationErrors(apiError.errors));
      } else {
        snackbar.enqueueSnackbar(apiError.message, ERROR);
      }
    }
  };

  const formInitValues = initialFormState(isEditMode ? details : null);

  const formik = useFormik({
    initialValues: formInitValues,
    onSubmit: handleSubmit,
    validationSchema: formValidation,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate className={styles.host}>
        <Grid container spacing={2} className={styles.controlsWrapper}>
          <Grid item xs={12}>
            <BaseInput
              label="Please provide a name for this payment method"
              id="name"
              name="name"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PaymentCard isCardSidebar />
          </Grid>
          <Grid item xs={12}>
            <BaseCheckbox
              id="isDefaultPayment"
              name="isDefaultPayment"
              label="Set as default"
            />
          </Grid>
        </Grid>
        <div className={styles.buttonWrapper}>
          {isEditMode && (
            <Box textAlign="center" paddingBottom={2}>
              <BaseButton onClick={onOpenDeletePaymentModal} danger>
                <DeleteIcon />
                <Typography variant="body1">Delete payment?</Typography>
              </BaseButton>
            </Box>
          )}
          <Divider />
          <Grid container spacing={2} className={styles.buttons}>
            <Grid item xs={6}>
              <BaseButtonLink
                size="large"
                variant="outlined"
                to={path}
                disableElevation
                fullWidth
              >
                Cancel
              </BaseButtonLink>
            </Grid>
            <Grid item xs={6}>
              <BaseButton
                size="large"
                color="primary"
                type="submit"
                variant="contained"
                disabled={formik.values.token === ''}
                loading={processing}
                disableElevation
                fullWidth
              >
                Save
              </BaseButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    </FormikProvider>
  );
};

PaymentCreditFormComponent.defaultProps = {
  paymentId: null,
  onDeletePayment: () => {},
};

export const PaymentCreditForm = memo(PaymentCreditFormComponent);
