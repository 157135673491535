import * as Yup from 'yup';

import Auth from 'models/Auth';

export const initialFormState: Auth = {
  email: '',
  password: '',
};

export const formValidation: Yup.SchemaOf<Auth> = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Enter an email'),
  password: Yup.string().trim()
    .required('Enter a password'),
});
