import * as Yup from 'yup';
import 'yup-phone';

import UserEdit from 'models/UserEdit';

interface ProfileEditFormState extends Omit<UserEdit, 'organizationId'> {
  readonly id: number;
}

export const initialFormState = ({
  firstName, lastName, email, phone, id,
}: ProfileEditFormState): UserEdit => ({
  firstName,
  lastName,
  email,
  phone,
  organizationId: id,
});

export const formValidation: Yup.SchemaOf<Omit<UserEdit, 'organizationId'>> = Yup.object().shape({
  firstName: Yup.string().trim()
    .required('Enter a first name'),
  lastName: Yup.string().trim()
    .required('Enter a last name'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Enter an email'),
  phone: Yup.string()
    .phone('US', false, 'Enter a valid phone number')
    .required('Enter a phone number'),
});
