import React from 'react';
import { Outlet } from 'react-router-dom';
import PaymentAddPage from './pages/PaymentAddPage/PaymentAddPage';
import PaymentEditPage from './pages/PaymentEditPage/PaymentEditPage';
import PaymentInfoPage from './pages/PaymentInfoPage/PaymentInfoPage';
import ProfileEditPage from './pages/ProfileEditPage/ProfileEditPage';
import ProfileInfoPage from './pages/ProfileInfoPage/ProfileInfoPage';
import ReoccurringEditPage from './pages/ReoccurringEditPage/ReoccurringEditPage';
import ReoccurringInfoPage from './pages/ReoccurringInfoPage/ReoccurringInfoPage';
import TransactionsInfoPage from './pages/TransactionsInfoPage/TransactionsInfoPage';
import UpdatePasswordPage from './pages/UpdatePasswordPage/UpdatePasswordPage';
import UserProfilePage from './pages/UserProfilePage/UserProfilePage';

export const userRoutes = [
  {
    path: '',
    element: <UserProfilePage />,
  },
  {
    path: 'transactions',
    element: <TransactionsInfoPage />,
  },
  {
    path: 'reoccurring',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <ReoccurringInfoPage />,
      },
      {
        path: 'edit/:id',
        element: <ReoccurringEditPage />,
      },
    ],
  },
  {
    path: 'payment',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <PaymentInfoPage />,
      },
      {
        path: 'edit/:id',
        element: <PaymentEditPage />,
      },
      {
        path: 'add',
        element: <PaymentAddPage />,
      },
    ],
  },
  {
    path: 'profile',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <ProfileInfoPage />,
      },
      {
        path: 'edit',
        element: <ProfileEditPage />,
      },
      {
        path: 'update-password',
        element: <UpdatePasswordPage />,
      },
    ],
  },
];
