import React, {
  memo, useState, ChangeEvent, useMemo,
} from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import clsx from 'clsx';
import {
  Paper, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography,
} from '@material-ui/core';

import {
  BaseCheckbox, SelectPaymentMethod, PaymentCard, ACH,
} from 'components';
import { useAppSelector } from 'store/rootReducer';
import { PaymentMethod } from 'models/enums';

import styles from './PaymentMethodReoccurring.module.css';

const PaymentMethodReoccurringComponent = (): JSX.Element => {
  const { setFieldValue, setValues, values } = useFormikContext<FormikValues>();
  const [field] = useField('paymentMethod');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.Saved);

  const { data: organization } = useAppSelector((state) => state.organization);
  const { list } = useAppSelector((state) => state.userPayment);
  const { details } = useAppSelector((state) => state.userPayment.reoccurring);

  /**
   * It's changing paymentMethod at state.
   */
  const handlePaymentMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setPaymentMethod(value as PaymentMethod);

    if (value === PaymentMethod.Saved) {
      setFieldValue(field.name, PaymentMethod.Card);
      setFieldValue('isSavedPaymentMethod', true);
    } else {
      setValues({
        ...values,
        isSavedPaymentMethod: false,
        token: '',
        name: '',
        cardName: '',
        achName: '',
        accountNumber: '',
        routingNumber: '',
        last4: '',
        userPaymentId: null,
        zip: '',
      });
    }

    if (value === PaymentMethod.Card) {
      setFieldValue(field.name, PaymentMethod.Card);
    }

    if (value === PaymentMethod.Ach) {
      setFieldValue(field.name, value);
    }
  };

  const savedPaymentMethods = useMemo(() => {
    if (!details?.userPayment || list?.results.find((userPayment) => userPayment.id === details?.userPaymentId)) {
      return list?.results ?? [];
    }

    if (!list?.results) {
      return [details.userPayment];
    }

    return [...list.results, details.userPayment];
  }, [list?.results, details?.userPayment, details?.userPaymentId]);

  return (
    <Grid spacing={0} container>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Select Payment Method</FormLabel>
          <RadioGroup
            aria-label="payment method"
            className={styles.radioGroup}
            name="paymentMethod"
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <Grid item xs={12}>
              <FormControlLabel
                value={PaymentMethod.Saved}
                control={<Radio />}
                label={<Typography variant="body2">Saved Payment Methods</Typography>}
              />
              {paymentMethod === PaymentMethod.Saved
                && <SelectPaymentMethod data={savedPaymentMethods} isEditReoccurring />}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className={styles.radioButton}
                value={PaymentMethod.Card}
                control={<Radio />}
                label={<Typography variant="body2">New Debit/Credit</Typography>}
              />
              {paymentMethod === PaymentMethod.Card
                && (
                  <Paper className={clsx(styles.paper, 'greyPaper')} elevation={0}>
                    <PaymentCard isCardSidebar />
                    <BaseCheckbox
                      id="isFutureUse"
                      name="isFutureUse"
                      color="primary"
                      label="Save payment for future use"
                    />
                  </Paper>
                )}
            </Grid>
            <Grid item xs={12}>
              {organization?.achMerchantId && (
                <FormControlLabel
                  className={styles.radioButton}
                  value={PaymentMethod.Ach}
                  control={<Radio />}
                  label={<Typography variant="body2">New ACH</Typography>}
                />
              )}
              {paymentMethod === PaymentMethod.Ach && (
                <Paper className={clsx(styles.paper, 'greyPaper')} elevation={0}>
                  <ACH fullWidth />
                  <BaseCheckbox
                    id="isFutureUse"
                    name="isFutureUse"
                    color="primary"
                    label="Save payment for future use"
                  />
                </Paper>
              )}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const PaymentMethodReoccurring = memo(PaymentMethodReoccurringComponent);
