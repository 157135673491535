import React, { Suspense } from 'react';
import { LinearProgress } from '@material-ui/core';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { useRoutes } from 'react-router-dom';

import { router } from 'routes/RootRouter';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.querySelector('#jss-insertion-point') as HTMLElement,
});

const App = (): JSX.Element => {
  const routes = useRoutes(router);

  return (
    <StylesProvider jss={jss}>
      <div className="app-wrapper">
        <Suspense fallback={<LinearProgress />}>
          {routes}
        </Suspense>
      </div>
    </StylesProvider>
  );
};

export default App;
