import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik, Form, FormikHelpers } from 'formik';
import { Box, Divider, Grid } from '@material-ui/core';

import { BaseButton, BaseButtonLink, BaseInput } from 'components';
import { ERROR, SUCCESS } from 'components/AppSnackbar';
import { getValidationErrors } from 'utils/transformErrors';
import transformAPIErrors from 'utils/transformAPIErrors';
import { getInternationalFormat, getNumberFormat } from 'utils/phone';
import { ServerValidationError } from 'utils/exceptions';
import { update } from 'store/User';
import { logout } from 'store/Auth';
import { useAppSelector, useAppDispatch } from 'store/rootReducer';
import UserEdit from 'models/UserEdit';

import { formValidation, initialFormState } from './formSettings';
import styles from './ProfileEditForm.module.css';

interface ProfileEditFormProps {
  /**
   * Path for route.
   */
  path: string;
}

const ProfileEditFormComponent = ({
  path,
}: ProfileEditFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { processing, user } = useAppSelector((state) => state.user);
  const { data } = useAppSelector((state) => state.organization);

  const handleSubmit = async (values: UserEdit, { setErrors }: FormikHelpers<UserEdit>) => {
    try {
      await dispatch(update({ ...values, phone: getNumberFormat(values.phone) }));

      if (values.email === user?.email) {
        snackbar.enqueueSnackbar('Your profile has been changed successfully', SUCCESS);

        navigate(`${path}/sidebar/profile`);
      } else {
        await dispatch(logout());

        snackbar.enqueueSnackbar(
          <p className={styles.paragraph}>
            {`Your profile has been changed successfully. Please check your inbox for
            a confirmation email. Click the link in the email to verify your account`}
          </p>,
          SUCCESS,
        );

        navigate(`${path}`, { replace: true });
      }
    } catch (error) {
      const serverErrors = transformAPIErrors(error as Error);

      if (error instanceof ServerValidationError) {
        setErrors(getValidationErrors(serverErrors.errors));
      } else {
        snackbar.enqueueSnackbar(serverErrors.message, ERROR);
      }
    }
  };

  const formInitValues = initialFormState({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    phone: getInternationalFormat(user?.phone) ?? '',
    id: data?.id ?? 0,
  });

  return (
    <Formik
      initialValues={formInitValues}
      onSubmit={handleSubmit}
      validationSchema={formValidation}
      validateOnBlur={false}
    >
      <Form noValidate className={styles.form}>
        <Grid container spacing={1} className={styles.controlsWrapper}>
          <Grid item xs={6}>
            <BaseInput
              label="First Name"
              id="firstName"
              name="firstName"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <BaseInput
              label="Last Name"
              id="lastName"
              name="lastName"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInput
              label="Email"
              id="email"
              name="email"
              type="email"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseInput
              label="Phone Number"
              id="phone"
              name="phone"
              type="tel"
              required
            />
          </Grid>
        </Grid>
        <Box textAlign="right">
          <BaseButtonLink
            color="primary"
            className={styles.buttonRedirect}
            to={`${path}/sidebar/profile/update-password`}
          >
            Change Password
          </BaseButtonLink>
        </Box>
        <div className={styles.buttonWrapper}>
          <Divider />
          <Grid container spacing={2} className={styles.buttons}>
            <Grid item xs={6}>
              <BaseButtonLink
                size="large"
                variant="outlined"
                to={`${path}/sidebar/profile`}
                disableElevation
                fullWidth
              >
                Cancel
              </BaseButtonLink>
            </Grid>
            <Grid item xs={6}>
              <BaseButton
                size="large"
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
                fullWidth
                loading={processing}
              >
                Save
              </BaseButton>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
};

export const ProfileEditForm = memo(ProfileEditFormComponent);
