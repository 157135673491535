import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, Card, CardContent, IconButton, Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

import styles from './SidebarMenuCard.module.css';

interface SidebarMenuCardProps {
  /**
   * Path.
   */
  path: string;
  /**
   * Header Card.
   */
  title: string;
  /**
   * Header Card.
   */
  isBackToMenu?: boolean;
  /**
   * Variant to use Card.
   */
  variant?: 'elevation' | 'outlined';
}

const SidebarMenuCardComponent = ({
  path,
  title,
  isBackToMenu,
  variant,
}: SidebarMenuCardProps): JSX.Element => {
  const navigate = useNavigate();

  /**
   * Method for closing menu item and Profile pop up.
   */
  const handleCloseProfile = () => {
    navigate(path);
  };

  /**
   * Method for return back to menu.
   */
  const handleBackToMenu = () => {
    navigate(`${path}/sidebar`);
  };

  const actionCard = isBackToMenu
    ? (
      <Box display="flex" justifyContent="space-between">
        <IconButton aria-label="close" onClick={handleBackToMenu}>
          <MenuIcon />
        </IconButton>
        <IconButton aria-label="close" onClick={handleCloseProfile}>
          <CloseIcon />
        </IconButton>
      </Box>
    )
    : (
      <Box textAlign="right">
        <IconButton aria-label="close" onClick={handleCloseProfile}>
          <CloseIcon />
        </IconButton>
      </Box>
    );

  return (
    <Card variant={variant} className={styles.card} square>
      {actionCard}
      <CardContent className={styles.cardContent}>
        <Typography variant="h6" className={styles.itemTitle}>{title}</Typography>
      </CardContent>
    </Card>
  );
};

SidebarMenuCardComponent.defaultProps = {
  isBackToMenu: false,
  variant: 'elevation',
};

export const SidebarMenuCard = memo(SidebarMenuCardComponent);
