import React, { memo } from 'react';
import { Grid } from '@material-ui/core';

import { BaseInput } from 'components';

interface ACHProps {
  /**
   * Is full width.
   */
  fullWidth?: boolean;
}

const ACHComponent = ({ fullWidth }: ACHProps): JSX.Element => {
  const width = fullWidth ? 12 : 6;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={width}>
        <BaseInput
          label="Account Holder Name"
          id="achName"
          name="achName"
        />
      </Grid>
      <Grid item xs={12} className="emptyGrid" />
      <Grid item xs={12} sm={width}>
        <BaseInput
          label="Routing Number"
          id="routingNumber"
          name="routingNumber"
          inputMode="numeric"
          inputProps={{
            maxLength: 12,
          }}
        />
      </Grid>
      <Grid item xs={12} className="emptyGrid" />
      <Grid item xs={12} sm={width}>
        <BaseInput
          label="Account Number"
          id="accountNumber"
          name="accountNumber"
          inputMode="numeric"
          inputProps={{
            maxLength: 12,
          }}
        />
      </Grid>
    </Grid>
  );
};

ACHComponent.defaultProps = {
  fullWidth: false,
};

export const ACH = memo(ACHComponent);
