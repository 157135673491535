import { ReoccurringPaymentRequestDto, ReoccurringPaymentResponseDto } from 'api/dtos/reoccurringPaymentDto';
import ResponseDto from 'api/dtos/responseDto';
import PagedList from 'models/PagedList';
import ReoccurringPayment from 'models/ReoccurringPayment';
import { mapDtoToPagination } from './paginationMapper';
import { mapDtoToPaymentFrequency } from './paymentFrequencyMapper';
import { mapDtoToUserPayment } from './userPaymentMapper';

/**
 * Map ReoccurringPayment info model to DTO.
 */
export const mapReoccurringPaymentToDto = (payload: ReoccurringPayment): ReoccurringPaymentRequestDto => ({
  id: payload.id,
  user_payment_id: payload.userPaymentId,
  amount: payload.amount,
  donated_to: payload.donatedTo,
  frequency: payload.frequency,
  charge_at: payload.chargeAt,
  token: payload.token,
  expiry: payload.expiry,
  payment_method: payload.paymentMethod,
  last_4: payload.last4,
  card_name: payload.cardName ?? null,
  is_future_use: payload.isFutureUse ?? null,
  zip: payload.zip ?? null,
});

/**
 * Map DTO to ReoccurringPayment model.
 */
export const mapDtoToReoccurringPayment = (dto: ReoccurringPaymentResponseDto):
ReoccurringPayment => new ReoccurringPayment({
  id: dto.id,
  userPaymentId: dto.user_payment_id,
  userPayment: dto.userPayment && mapDtoToUserPayment(dto.userPayment),
  amount: dto.amount,
  donatedTo: dto.donated_to,
  frequency: dto.payment_frequency.id,
  paymentFrequency: mapDtoToPaymentFrequency(dto.payment_frequency),
  chargeAt: dto.charge_at,
  paymentMethod: dto.payment_method,
  last4: dto.last_4,
  token: dto.token,
  expiry: dto.expiry,
  zip: dto.zip,
});

/**
 * Map DTO to ReoccurringPayment list.
 */
export function mapDtoToReoccurringPayments(dtos: ResponseDto<ReoccurringPaymentResponseDto>):
PagedList<ReoccurringPayment> {
  return new PagedList<ReoccurringPayment>({
    pagination: mapDtoToPagination(dtos.pagination),
    results: dtos.results?.map(mapDtoToReoccurringPayment),
  });
}
