import React, { memo } from 'react';
import { useField } from 'formik';
import {
  OutlinedInput, OutlinedInputProps, FormLabel, FormControl, FormHelperText,
} from '@material-ui/core';

import styles from './BaseInput.module.css';

interface BaseInputProps extends OutlinedInputProps {
  /**
   * Field name.
   */
  name: string;
  /**
   * Check field is required.
   */
  required?: boolean;
}

const BaseInputComponent = ({
  id, label, name, required, ...props
}: BaseInputProps): JSX.Element => {
  const [field, meta] = useField(name);

  const configFormControl = {
    fullWidth: true,
    error: false,
    required,
  };

  const configTextField = {
    ...field,
    ...props,
    id,
    fullWidth: true,
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  return (
    <FormControl {...configFormControl} className={styles.host}>
      <FormLabel className={styles.label} htmlFor={id}>{label}</FormLabel>
      <OutlinedInput {...configTextField} />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseInputComponent.defaultProps = {
  required: false,
};

export const BaseInput = memo(BaseInputComponent);
