import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import User from 'models/User';
import ApiError from 'models/ApiError';
import { create, getUserTransactions } from 'store/User';
import PagedList from 'models/PagedList';
import UserTransaction from 'models/UserTransaction';
import { state as initialState } from './state';

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser() {
      return initialState;
    },
    setUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    setUserSuccess(state, { payload }: PayloadAction<User>) {
      state.user = payload;
      state.loading = false;
    },
    setUserFailed(state, { payload }: PayloadAction<ApiError>) {
      state.error = payload?.message;
      state.loading = false;
    },
    setUserUpdateStart(state) {
      state.processing = true;
      state.error = null;
    },
    setUserUpdateSuccess(state, { payload }: PayloadAction<User>) {
      state.user = payload;
      state.processing = false;
    },
    setUserUpdateFailed(state, { payload }: PayloadAction<ApiError>) {
      state.error = payload.message;
      state.processing = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(create.pending, (state) => {
      state.processing = true;
      state.error = null;
    });
    builder.addCase(create.fulfilled, (state) => {
      state.processing = false;
    });
    builder.addCase(create.rejected, (state, { payload }) => {
      state.error = payload as Error;
      state.processing = false;
    });
    builder.addCase(getUserTransactions.pending, (state) => {
      state.transactions.loading = true;
      state.transactions.error = null;
    });
    builder.addCase(
      getUserTransactions.fulfilled,
      (state, { payload }: PayloadAction<PagedList<UserTransaction>>) => {
        state.transactions.data = payload;
        state.transactions.loading = false;
      },
    );
    builder.addCase(getUserTransactions.rejected, (state, { error }) => {
      state.transactions.error = error.message ?? '';
      state.transactions.loading = false;
    });
  },
});

export const {
  resetUser,
  setUserStart,
  setUserSuccess,
  setUserFailed,
  setUserUpdateStart,
  setUserUpdateSuccess,
  setUserUpdateFailed,
} = userSlice.actions;

export const user = userSlice.reducer;
