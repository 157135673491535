import * as Yup from 'yup';
import 'yup-phone';

import Register from 'models/Register';

export const initialFormState = (id: number): Register => ({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phone: '',
  organizationId: id,
});

export const formValidation: Yup.SchemaOf<Omit<Register, 'organizationId'>> = Yup.object().shape({
  firstName: Yup.string().trim()
    .required('Enter a first name'),
  lastName: Yup.string().trim()
    .required('Enter a last name'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Enter an email'),
  phone: Yup.string()
    .phone('US', false, 'Enter a valid phone number')
    .required('Enter a phone number'),
  password: Yup.string().trim()
    .required('Enter a password').min(8, 'The password must be at least 8 characters'),
});
