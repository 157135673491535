import React, { memo, useEffect, useState } from 'react';
import {
  Outlet, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';

import {
  AppFooter, AppHeader, Loading,
} from 'components';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { getOrganizationBySlug } from 'store/Organization';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import { setSessionToken } from 'api/services/Session';
import { getUser } from 'store/User';
import { getUserPayments } from 'store/UserPayment';

import styles from './MainLayout.module.css';

const MainLayoutComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const params = useParams();
  const [queryParams] = useSearchParams();
  const token = queryParams.get('token');

  const navigate = useNavigate();

  const [serverError, setServerError] = useState(false);

  const { loading } = useAppSelector((state) => state.organization);
  const { user } = useAppSelector((state) => state.user);

  const preview = Boolean(searchParams.get('preview')) ?? false;

  useEffect(() => {
    if (params.slug) {
      dispatch(getOrganizationBySlug({ slug: params?.slug, preview })).unwrap().catch((error) => {
        if ((error as Error)?.message.includes('ServerError')) {
          setServerError(true);
        }
      });
    }
    dispatch(getUser());
  }, [dispatch, preview, params.slug]);

  useEffect(() => {
    if (token) {
      setSessionToken(token);
      navigate(`/${params.slug}`, { replace: true });
    }
  }, [navigate, params.slug, token]);

  useEffect(() => {
    if (user) {
      dispatch(getUserPayments());
    }
  }, [dispatch, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <AppHeader userName={user?.fullName || null} isOrganization={Boolean(params.slug)} />
      <main>
        <section className={styles.pageContainer}>
          { serverError ? <PageNotFound /> : <Outlet />}
        </section>
      </main>
      <AppFooter />
    </>
  );
};

export default memo(MainLayoutComponent);
