import React, { memo } from 'react';
import { ButtonProps } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { BaseButton } from 'components';
import SortParams from 'models/QueryParams';
import { ERROR } from 'components/AppSnackbar';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { exportToFile } from 'store/Utils';

interface CharitiesToolbarProps extends ButtonProps {
  /**
   * Endpoint path.
   */
  endpointPath: string;

   /**
   * Query params.
   */
  queryParams: SortParams;
}

const ExportButtonComponent = ({ endpointPath, queryParams, ...rest }: CharitiesToolbarProps): JSX.Element => {
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const { processing } = useAppSelector((state) => state.utils);

  const handleExport = async () => {
    try {
      await dispatch(exportToFile({ queryParams, endpointPath })).unwrap();
    } catch (error) {
      const { message } = error as Error;
      snackbar.enqueueSnackbar(message, ERROR);
    }
  };

  return (
    <BaseButton
      {...rest}
      color="primary"
      variant="contained"
      disableElevation
      onClick={handleExport}
      loading={processing}
    >
      Export
    </BaseButton>
  );
};

export const ExportButton = memo(ExportButtonComponent);
