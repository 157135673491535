import React, { memo, useState, MouseEvent } from 'react';
import {
  DialogProps, Dialog, DialogContent, DialogTitle, Box, IconButton, DialogActions, Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { AuthLayout } from 'layout/AuthLayout/AuthLayout';
import { BaseButton } from 'components';

import { SignUpForm } from '../SignUpForm';
import { SignUpCompletedModal } from '../SignUpCompletedModal';
import styles from './SignUpModal.module.css';

interface SignUpModalComponentProps extends DialogProps {
  onChangeModal: () => void;
  onSuccess?: () => void;
}

const SignUpModalComponent = ({
  onChangeModal,
  open,
  onClose,
  onSuccess,
  ...props
}: SignUpModalComponentProps): JSX.Element => {
  const [isOpenSignUpCompleted, setOpenSignUpCompleted] = useState(false);

  const handleClosePrevent = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      if (reason !== 'backdropClick') {
        onClose(event, reason);
      }
    }
  };

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    // onClose is optional props. Need to pass event and reason 'escapeKeyDown' for closing by Esc.
    if (onClose) {
      onClose(event, 'escapeKeyDown');
    }
  };

  const handleOpenSignUpCompleted = () => {
    setOpenSignUpCompleted(true);
    handleClose({} as MouseEvent<HTMLButtonElement>);
  };

  const handleCloseSignUpCompleted = () => {
    setOpenSignUpCompleted(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <>
      <Dialog
        {...props}
        open={open}
        aria-labelledby="alert-dialog-title"
        onClose={(event, reason) => handleClosePrevent(event, reason)}
        disableEscapeKeyDown
        className={styles.host}
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div className={styles.container}>
            <AuthLayout>
              <Box textAlign="center" mb={5}>
                <Typography variant="h4" className={styles.title}>Sign Up</Typography>
                <Typography variant="caption" className={styles.caption}>
                  Sign up to view transactions, manage recurring donations
                  and update payment methods and profile
                </Typography>
              </Box>
              <SignUpForm onSuccess={handleOpenSignUpCompleted} />
            </AuthLayout>
          </div>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Box textAlign="center">
            Already have an account?&nbsp;
            <BaseButton color="primary" onClick={onChangeModal}>Sign In</BaseButton>
          </Box>
        </DialogActions>
      </Dialog>
      <SignUpCompletedModal open={isOpenSignUpCompleted} onClose={handleCloseSignUpCompleted} />
    </>
  );
};

SignUpModalComponent.defaultProps = {
  onSuccess: undefined,
};

export const SignUpModal = memo(SignUpModalComponent);
