import React, { memo, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer } from '@material-ui/core';

import { useAppSelector } from 'store/rootReducer';

import styles from './AppSidebar.module.css';

const AppSidebarComponent = (): JSX.Element => {
  const [isOpenSidebar, setOpenSidebar] = useState(false);
  const location = useLocation();

  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (location.pathname.includes('sidebar') && Boolean(user)) {
      setOpenSidebar(true);
    } else {
      setOpenSidebar(false);
    }
  }, [user, location.pathname, setOpenSidebar]);

  return (
    <Drawer
      anchor="right"
      className={clsx('app-wrapper', styles.host)}
      variant="temporary"
      open={isOpenSidebar}
    >
      <Outlet />
    </Drawer>
  );
};

export const AppSidebar = memo(AppSidebarComponent);
