import React, {
  memo, useState, useEffect, MouseEvent,
} from 'react';
import clsx from 'clsx';
import { FormikValues, useField, useFormikContext } from 'formik';
import { FormHelperText, LinearProgress, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { CheckIcon } from 'components/Icons';
import { getLast4 } from 'utils/getLast4';
import { useAppSelector } from 'store/rootReducer';
import UserPayment from 'models/UserPayment';

import styles from './SelectPaymentMethod.module.css';

interface PaymentRadioProps {
  /**
   * Checked.
   */
  checked: boolean;
  /**
   * Last 4 numbers of a card number(account).
   */
  last4: string;
  /**
   * Payment name.
   */
  name: string;
  /**
   * Check if component has limited width.
   */
  limitWidth: boolean
}

const PaymentItem = ({
  checked, last4, name, limitWidth,
}: PaymentRadioProps) => {
  const paymentNameClass = limitWidth ? clsx(styles.maxWidth, styles.paymentName) : styles.paymentName;

  return (
    <div className={styles.paymentItem}>
      <div className={styles.iconPlaceholder}>{checked && <CheckIcon /> }</div>
      <span className={paymentNameClass} title={name}>
        {name}
      </span>
      <span className={styles.paymentData}>
        {last4}
      </span>
    </div>
  );
};

interface UserPaymentMethodsProps {
  /**
   * User payment data.
   */
  data: UserPayment[];
  /**
   * State of loading data.
   */
  loading?: boolean;
  /**
   * Check if component is rendered at ReoccurringForm.
   */
  isEditReoccurring?: boolean;
}

const SelectPaymentMethodComponent = ({ loading, data, isEditReoccurring }: UserPaymentMethodsProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<FormikValues>();
  const { details } = useAppSelector((state) => state.userPayment.reoccurring);
  const [paymentMethod, setPaymentMethod] = useState<number | null | undefined>(null);
  const [, meta] = useField('userPaymentId');

  const defaultPayment = isEditReoccurring
    ? details?.userPayment
    : data.find((userPayment: UserPayment) => userPayment.isDefaultPayment);

  useEffect(() => {
    if (defaultPayment) {
      setPaymentMethod(defaultPayment.id);
    }

    setFieldValue('paymentMethod', defaultPayment?.paymentMethod);
    setFieldValue('cardName', defaultPayment?.cardName ?? '');
    setFieldValue('zip', defaultPayment?.zip ?? '');
    setFieldValue('last4', defaultPayment?.last4);
    setFieldValue('userPaymentId', defaultPayment?.id);
  }, [setFieldValue, defaultPayment]);

  if (!loading && data?.length === 0) {
    return <Typography variant="body2">No Data</Typography>;
  }

  const handleSavedPaymentMethodSelect = (event: MouseEvent<HTMLElement>, paymentMethod: number) => {
    if (paymentMethod) {
      setPaymentMethod(paymentMethod);

      const payment = data.find((userPayment: UserPayment) => userPayment.id === paymentMethod);

      setFieldValue('paymentMethod', payment?.paymentMethod);
      setFieldValue('cardName', payment?.cardName ?? '');
      setFieldValue('zip', payment?.zip ?? '');
      setFieldValue('last4', payment?.last4);
      setFieldValue('userPaymentId', payment?.id);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  let helperText: string | null = null;

  if (meta && meta.error) {
    helperText = meta.error;
  }

  return (
    <>
      <ToggleButtonGroup
        value={paymentMethod}
        exclusive
        orientation="vertical"
        onChange={handleSavedPaymentMethodSelect}
        aria-label="Select saved payment method"
        className={styles.host}
      >
        {data.map((method) => (
          <ToggleButton
            value={method.id}
            aria-label={method.cardName || ''}
            key={method.id}
            className={styles.button}
          >
            <PaymentItem
              checked={method.id === paymentMethod}
              last4={getLast4(method.last4)}
              name={method.name || ''}
              limitWidth={isEditReoccurring ?? false}
            />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormHelperText error>{helperText}</FormHelperText>
    </>
  );
};

SelectPaymentMethodComponent.defaultProps = {
  loading: false,
  isEditReoccurring: false,
};

export const SelectPaymentMethod = memo(SelectPaymentMethodComponent);
