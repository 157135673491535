import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

/* eslint-disable max-len */
export const PaymentIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} width="22px" height="22px" viewBox="0 0 22 22">
    <g id="ELEMENT-SHEET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-188.000000, -393.000000)" fill="#FFFFFF" id="icons/payment-only">
        <g transform="translate(188.000000, 393.000000)">
          <path d="M20.6100615,9.60526062 C20.8714259,9.60526062 21.0833333,9.8171681 21.0833333,10.0785325 L21.0833333,10.0785325 L21.0833333,15.2976564 C21.0833333,16.8006101 19.8649738,18.0189697 18.3620201,18.0189697 L18.3620201,18.0189697 L3.63797994,18.0189697 C2.13502623,18.0189697 0.916666667,16.8006101 0.916666667,15.2976564 L0.916666667,15.2976564 L0.916666667,10.0785325 C0.916666667,9.8171681 1.12857415,9.60526062 1.38993854,9.60526062 L1.38993854,9.60526062 Z M5.95176669,11.734984 L4.68970836,11.734984 C4.23229109,11.734984 3.86148258,12.1057926 3.86148258,12.5632098 C3.86148258,13.0206271 4.23229109,13.3914356 4.68970836,13.3914356 L4.68970836,13.3914356 L5.95176669,13.3914356 C6.40918395,13.3914356 6.77999246,13.0206271 6.77999246,12.5632098 C6.77999246,12.1057926 6.40918395,11.734984 5.95176669,11.734984 L5.95176669,11.734984 Z M18.3620201,4.58333333 C19.8649738,4.58333333 21.0833333,5.80169289 21.0833333,7.3046466 L21.0833333,7.3046466 L21.0833333,7.47553719 C21.0833333,7.73690158 20.8714259,7.94880906 20.6100615,7.94880906 L20.6100615,7.94880906 L1.38993854,7.94880906 C1.12857415,7.94880906 0.916666667,7.73690158 0.916666667,7.47553719 L0.916666667,7.47553719 L0.916666667,7.3046466 C0.916666667,5.80169289 2.13502623,4.58333333 3.63797994,4.58333333 L3.63797994,4.58333333 Z" id="Combined-Shape" />
        </g>
      </g>
    </g>
  </SvgIcon>
);
