import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { List, Typography } from '@material-ui/core';

import { Loading } from 'components';
import { useAppDispatch, useAppSelector } from 'store/rootReducer';
import { getReoccurringPayments, resetReoccurringPayment } from 'store/UserPayment';
import { SidebarMenuCard } from 'features/user/components/SidebarMenuCard';
import { UserReoccurringPayment } from 'features/user/components/UserReoccurringPayment';
import styles from './ReoccurringInfoPage.module.css';

const ReoccurringInfoPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();

  const { list, loading } = useAppSelector((state) => state.userPayment.reoccurring);

  useEffect(() => {
    if (!list) {
      dispatch(getReoccurringPayments());
    }
  }, [dispatch, list]);

  useEffect(() => {
    if (!params.id) {
      dispatch(resetReoccurringPayment());
    }
  }, [dispatch, params.id]);

  const payments = list?.results || [];

  const content = payments.length === 0
    ? <Typography variant="body2" className={styles.noDataText}>No data to display yet</Typography>
    : payments.map((payment) => (
      <UserReoccurringPayment
        key={payment.id}
        path={`${location.pathname}/edit/${payment.id}`}
        payment={payment}
      />
    ));

  return (
    <>
      <Helmet>
        <title>Recurring Info Page</title>
      </Helmet>

      <div className={styles.container}>
        <SidebarMenuCard path={`/${params.slug}`} title="Manage Recurring Payments" isBackToMenu />
        <List className={styles.listWrapper}>
          {loading ? <Loading /> : content}
        </List>
      </div>
    </>
  );
};

export default ReoccurringInfoPage;
