import { Donation, DonationResponse } from 'models/Donation';
import { DonationDto, DonationResponseDto } from 'api/dtos/donationDto';

/**
 * Map Donation info model to DTO.
 */
export const mapDonationToDto = (payload: Donation): DonationDto => ({
  frequency_type: payload.frequencyType,
  frequency: payload?.frequency,
  charge_on: payload.chargeOn,
  is_transaction_fee: payload.isTransactionFee,
  token: payload?.token,
  expiry: payload?.expiry,
  payment_method: payload.paymentMethod,
  donate_as: payload?.donateAs,
  last_4: payload.last4,
  receipt: payload.receipt,
  name: payload?.name,
  ach_name: payload?.achName,
  card_name: payload?.cardName,
  charities: payload.charities,
  user_payment_id: payload?.userPaymentId,
  is_future_use: payload?.isFutureUse,
  zip: payload?.zip,
  ach_merchant_id: payload?.achMerchantId,
});

/**
 * Map DTO to Donation model.
 */
export const mapDtoToDonation = (dto: DonationResponseDto): DonationResponse => new DonationResponse({
  isDonationSent: dto.is_donation_sent,
});
