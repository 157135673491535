import * as Yup from 'yup';

import UserPayment from 'models/UserPayment';
import { UserSavePaymentControl } from 'models/UserSavePayment';

export type PaymentFormState = Omit<UserSavePaymentControl, 'paymentMethod'>;

export const initialFormState = (paymentInfo: UserPayment | null): PaymentFormState => ({
  name: paymentInfo?.name ?? '',
  cardName: paymentInfo?.cardName ?? '',
  isDefaultPayment: paymentInfo?.isDefaultPayment ?? false,
  token: '',
  expiry: '',
  zip: paymentInfo?.zip ?? '',
});

export const formValidation: Yup.SchemaOf<Omit<
  PaymentFormState, 'isDefaultPayment'|'account'|'expiry'|'achName'
>> = Yup.object().shape({
  name: Yup.string().trim().required('Enter a name'),
  cardName: Yup.string().trim().required('Enter a card name').max(100, 'Max size 100 characters'),
  token: Yup.string().required('Enter a card'),
  zip: Yup.string().trim().notRequired().max(9, 'Max size 9 characters'),
});
