import React from 'react';
import { Typography, Container, Link } from '@material-ui/core';

import styles from './AppFooter.module.css';

const PRIVACY_POLICY = 'https://generosityonline.com/privacy-policy';
const TERMS_AND_CONDITION = 'https://generosityonline.com/terms-of-service';
const GENEROSITY = 'https://generosityonline.com';

export const AppFooter = (): JSX.Element => (
  <footer className={styles.host}>
    <Container maxWidth="md" className={styles.container}>
      <Typography variant="caption" className={styles.pageTitle} align="center">
        Powered by&nbsp;
        <Link href={GENEROSITY} target="_blank" className={styles.link}>
          <strong>Generosity</strong>
        </Link>
      </Typography>
      <ul className={styles.menu}>
        <li>
          <Link href={PRIVACY_POLICY} target="_blank" className={styles.link}>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link href={TERMS_AND_CONDITION} target="_blank" className={styles.link}>
            Terms of Conditions
          </Link>
        </li>
      </ul>
    </Container>
  </footer>
);
