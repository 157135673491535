import React, {
  useState, memo, ChangeEvent, ElementType,
} from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import MaskedInput, { Mask, MaskedInputProps } from 'react-text-mask';
import {
  FormControl, FormHelperText, FormLabel, InputBaseComponentProps, InputProps, OutlinedInput,
} from '@material-ui/core';

import styles from './BaseMaskedInput.module.css';

interface BaseMaskedInputProps extends InputProps {
  /**
   * Unique id.
   */
  id: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Text label.
   */
  label: string;
  /**
   * Optional mask rules.
   */
  mask?: Mask | ((value: string) => Mask);
  /**
   * Text mask.
   * @example __-______
   */
  textMask?: string;
}

interface MaskInputProps extends MaskedInputProps {
  inputRef: (ref: HTMLElement | null) => void;
}

const MaskInput = ({ inputRef, ...props }: MaskInputProps): JSX.Element => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
  />
);

const BaseMaskedInputComponent = ({
  id, name, label, mask, textMask, ...props
}: BaseMaskedInputProps): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [field, meta] = useField(name);
  const [value, setValue] = useState<string | null>(values[name] || textMask);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setFieldValue(name, event.target.value);
  };

  const configFormControl = {
    error: false,
    fullWidth: true,
  };

  const configTextField = {
    ...field,
    ...props,
    id,
    value,
    onChange: handleChange,
  };

  let helperText: string | null = null;

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  return (
    <FormControl {...configFormControl} className={styles.host}>
      <FormLabel className={styles.label} htmlFor={id}>{label}</FormLabel>
      <OutlinedInput
        {...configTextField}
        inputComponent={MaskInput as ElementType<InputBaseComponentProps>}
        inputProps={{ mask }}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  );
};

BaseMaskedInputComponent.defaultProps = {
  mask: [/[1-9]/, /[1-9]/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  textMask: '__-______',
};

export const BaseMaskedInput = memo(BaseMaskedInputComponent);
