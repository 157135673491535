import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography, Card, CardContent, CardMedia, IconButton, Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useAppSelector } from 'store/rootReducer';
import logo from 'assets/images/middle-logo.svg';
import styles from './UserProfileMenuCard.module.css';

interface UserProfileMenuCardProps {
  /**
   * Data for route after go to the Link.
   */
  path: string;
  /**
   * Method for closing Profile pop up.
   */
  onCloseProfile: () => void;
}

const UserProfileMenuCardComponent = ({
  path,
  onCloseProfile,
}: UserProfileMenuCardProps): JSX.Element => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <Card variant="elevation" className={styles.card} square>
      <Box textAlign="right">
        <IconButton aria-label="close" onClick={onCloseProfile}>
          <CloseIcon />
        </IconButton>
      </Box>
      <CardContent className={styles.cardContent}>
        <Link to={path}>
          <CardMedia
            className={styles.logo}
            image={logo}
            component="img"
            height="24"
            title="Generosity"
          />
        </Link>
        <Typography variant="body2" className={styles.hello}>Hello,</Typography>
        <Typography variant="h6" className={styles.userName}>{user?.fullName}</Typography>
      </CardContent>
    </Card>
  );
};

export const UserProfileMenuCard = memo(UserProfileMenuCardComponent);
