import React, {
  memo, ChangeEvent, ReactNode,
} from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { BaseDatepicker, BaseSelect, DayOfWeekPicker } from 'components';
import { useAppSelector } from 'store/rootReducer';
import { Frequency } from 'models/enums';
import {
  DATE_PICKER_FORMAT, DAYS, getDayOfWeek, getFormattedDate, SHORT_FORMAT,
} from 'utils/date';

const ReoccurringUpdateComponent = (): JSX.Element | null => {
  const { loading, data } = useAppSelector((state) => state.site.paymentFrequency);

  const frequencyOptions = data?.results ?? [];

  const { setFieldValue, values } = useFormikContext<FormikValues>();

  const handleFrequencyChange = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    setFieldValue('chargeAt', null);
    setFieldValue('frequencyId', value);
  };

  const handleDisableToday = (day: MaterialUiPickersDate) => {
    const selectedDay = getFormattedDate(day, SHORT_FORMAT);
    const today = getFormattedDate(new Date(), SHORT_FORMAT);

    return selectedDay === today;
  };

  const initValueDayOfWeek = DAYS[getDayOfWeek(values.chargeAt)];

  const getFrequency = (): ReactNode => {
    switch (values.frequencyId) {
      case Frequency.Monthly: {
        return (
          <Grid item xs={7}>
            <BaseDatepicker
              id="chargeAtMonthly"
              label="Charge On"
              name="chargeAt"
              format={DATE_PICKER_FORMAT}
              isShowIcon
              disablePast
              shouldDisableDate={handleDisableToday}
            />
          </Grid>
        );
      }

      case Frequency.Weekly: {
        return (
          <Grid item xs={12}>
            <DayOfWeekPicker
              label="Repeat payment on"
              name="chargeAt"
              key="weekly"
              initValue={initValueDayOfWeek}
            />
          </Grid>
        );
      }

      case Frequency.BiWeekly: {
        return (
          <Grid item xs={12}>
            <DayOfWeekPicker
              label="Repeat payment on"
              name="chargeAt"
              key="biWeekly"
              initValue={initValueDayOfWeek}
            />
          </Grid>
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <Grid spacing={2} container>
      <Grid item xs={5}>
        <BaseSelect
          label="Frequency"
          id="frequencyId"
          name="frequencyId"
          itemKey="id"
          itemValue="name"
          options={frequencyOptions}
          onChange={handleFrequencyChange}
          loading={loading}
        />
      </Grid>
      {getFrequency()}
    </Grid>
  );
};

export const ReoccurringUpdate = memo(ReoccurringUpdateComponent);
