import { decamelize } from 'humps';
import SortQueryParamsDto from 'api/dtos/queryParamsDto';
import SortParams from 'models/QueryParams';

/**
 * Map User Query params to DTO.
 */
export const mapQueryParamsToDto = (payload: SortParams): SortQueryParamsDto => {
  const { orderBy, sortOrder } = payload;

  return {
    order_by: orderBy ? decamelize(orderBy) : orderBy,
    sort_order: sortOrder,
  };
};
