/**
 *  Donation model class.
 */
// eslint-disable-next-line max-classes-per-file
export class Donation {
  /**
   * Frequency type.
   */
  public readonly frequencyType: string;

  /**
   * Frequency.
   */
  public readonly frequency?: number | null;

  /**
   * Charge on.
   */
  public readonly chargeOn?: string | Date | null;

  /**
   * Is future use.
   */
  public readonly isFutureUse: boolean;

  /**
   * Is transaction fee.
   */
  public readonly isTransactionFee: boolean | null;

  /**
   * Cardpointe token.
   */
  public readonly token?: string;

  /**
   * Cardpointe expiry.
   */
  public readonly expiry?: string;

  /**
   * Payment method.
   */
  public readonly paymentMethod: string;

  /**
   * Cardholder name.
   */
  public readonly cardName?: string | null;

  /**
   * Account number.
   */
  public readonly account?: string;

  /**
   * Donate as.
   */
  public readonly donateAs?: string | null;

  /**
   * Receipt recipient.
   */
  public readonly receipt: string;

  /**
   * Last 4 numbers of a card number(account).
   */
  public readonly last4: string;

  /**
   * Name.
   */
  public readonly name?: string | null;

  /**
   * ACH Name.
   */
  public readonly achName?: string | null;

  /**
   * List of charities.
   */
  public readonly charities: Record<string, CharityFee>[];

  /**
   * User payment id.
   */
  public readonly userPaymentId?: number;

  /**
   * ZIP.
   */
  public readonly zip?: string | null;

  /**
   * ACH merchant id.
   */
  public readonly achMerchantId: string | null;

  constructor(data: Donation) {
    this.frequencyType = data.frequencyType;
    this.frequency = data.frequency;
    this.chargeOn = data.chargeOn;
    this.isFutureUse = data.isFutureUse;
    this.isTransactionFee = data.isTransactionFee;
    this.token = data.token;
    this.expiry = data.expiry;
    this.paymentMethod = data.paymentMethod;
    this.cardName = data.cardName;
    this.account = data.account;
    this.donateAs = data.donateAs;
    this.receipt = data.receipt;
    this.last4 = data.last4;
    this.name = data.name;
    this.achName = data.achName;
    this.charities = data.charities;
    this.userPaymentId = data.userPaymentId;
    this.zip = data.zip;
    this.achMerchantId = data.achMerchantId;
  }
}

/**
 * Donation charity data type.
 */
interface CharityFee {
  amount: string,
  comment?: string,
}

/**
 *  Donation model class.
 */
export class DonationResponse {
  /**
   * Is donation sent.
   */
  public readonly isDonationSent: boolean;

  constructor(data: DonationResponse) {
    this.isDonationSent = data.isDonationSent;
  }
}
