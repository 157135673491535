import * as Yup from 'yup';

export const initialFormState = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

export type UpdatePassword = typeof initialFormState;

export const formValidation: Yup.SchemaOf<UpdatePassword> = Yup.object().shape({
  oldPassword: Yup.string().trim()
    .required('Enter an old password'),
  newPassword: Yup.string().trim()
    .min(8, 'The password must be at least 8 characters')
    .required('Enter a new password'),
  newPasswordConfirmation: Yup.string().trim()
    .required('Confirm password')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords don’t match'),
});
