import PaymentToken, { PaymentTokenResponse } from 'models/PaymentToken';
import { PaymentTokenDto, PaymentTokenResponseDto } from 'api/dtos/paymentTokenDto';

/**
 * Map DTO to Payment token model.
 */
export const mapDtoToPaymentToken = (dto: PaymentTokenResponseDto): PaymentTokenResponse => new PaymentTokenResponse({
  token: dto.token,
});

/**
 * Map Payment token info model to DTO.
 */
export const mapPaymentTokenToDto = (payload: PaymentToken): PaymentTokenDto => ({
  account: payload.account || '',
});
