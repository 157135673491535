import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

/* eslint-disable max-len */
export const TransactionIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} width="36px" height="28px" viewBox="0 0 36 28">
    <g id="ELEMENT-SHEET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-469.000000, -385.000000)" fill="#3A4656" id="icons/updateprofile-only">
        <path d="M500.913128,385 C503.291828,385.172521 505.101678,387.183699 504.994629,389.538636 L504.994629,389.538636 L504.994629,408.461435 C505.11181,410.820434 503.297597,412.838553 500.913128,413 L500.913128,413 L473.189566,413 C472.024788,412.948514 470.929057,412.4405 470.144557,411.588727 C469.358641,410.736953 468.948789,409.612395 469.005126,408.461364 L469.005126,408.461364 L469.005126,389.538565 C468.900564,387.146145 470.769726,385.119696 473.189385,385 L473.189385,385 Z M480.8,400 L477.2,400 C474.880031,400 473,401.68 473,403.759969 L473,403.759969 L473,404.5 C473,405.319969 473.680031,406 474.5,406 L474.5,406 L483.5,406 C484.319969,406 485,405.319969 485,404.5 L485,404.5 L485,403.759969 C485,401.68 483.119969,400 480.8,400 L480.8,400 Z M495.5,403 L490.5,403 C489.671573,403 489,403.671573 489,404.5 C489,405.328427 489.671573,406 490.5,406 L490.5,406 L495.5,406 C496.328427,406 497,405.328427 497,404.5 C497,403.671573 496.328427,403 495.5,403 L495.5,403 Z M499.5,398 L490.5,398 C489.671573,398 489,398.671573 489,399.5 C489,400.328427 489.671573,401 490.5,401 L490.5,401 L499.5,401 C500.328427,401 501,400.328427 501,399.5 C501,398.671573 500.328427,398 499.5,398 L499.5,398 Z M479,393 C477.340031,393 476,394.309489 476,395.931624 C476,397.553758 477.340031,398.863248 479,398.863248 C480.659969,398.863248 482,397.553758 482,395.931624 C482,394.309489 480.659969,393 479,393 Z M499.5,393 L490.5,393 C489.671573,393 489,393.671573 489,394.5 C489,395.328427 489.671573,396 490.5,396 L490.5,396 L499.5,396 C500.328427,396 501,395.328427 501,394.5 C501,393.671573 500.328427,393 499.5,393 L499.5,393 Z" />
      </g>
    </g>
  </SvgIcon>
);
