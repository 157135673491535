import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchPaymentFrequencies } from 'api/services/Site';

/**
 * Get payment frequencies list.
 */
export const getPaymentFrequencies = createAsyncThunk(
  'site/getPaymentFrequencies',
  () => fetchPaymentFrequencies(),
);
