import Organization from 'models/Organization';
import OrganizationDto from 'api/dtos/organizationDto';
import { mapDtoToCharities } from 'api/mappers/charityMapper';
import { mapDtoToBanner } from 'api/mappers/bannerMapper';

/**
 * Map DTO to Organization model.
 */
export const mapDtoToOrganization = (dto: OrganizationDto): Organization => new Organization({
  id: dto.id,
  name: dto.name,
  address: dto.address,
  city: dto.city,
  state: dto.state,
  zip: dto.zip,
  fee: Number(dto.fee),
  feeAmount: Number(dto.fee_amount),
  charities: mapDtoToCharities(dto.charities),
  banner: dto.banner ? mapDtoToBanner(dto.banner) : null,
  brandingColor: dto.branding_color,
  logoUrl: dto.logo_url,
  showLogo: dto.show_logo,
  showDonation: dto.show_donation,
  showFee: dto.show_fee,
  showBanner: dto.show_banner,
  achMerchantId: dto.ach_merchant_id,
  tackonBearerToken: dto.tackon_bearer_token,
  tackonPhone: dto.tackon_phone,
});
