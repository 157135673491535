import PagedList from 'models/PagedList';
import User from 'models/User';
import UserTransaction from 'models/UserTransaction';

interface UserState {
  loading: boolean;
  processing: boolean;
  user: User | null;
  error: string | null | Error;
  transactions: {
    loading: boolean;
    data: PagedList<UserTransaction> | null;
    error: string | null;
  },
}

export const state: UserState = {
  loading: false,
  processing: false,
  user: null,
  error: null,
  transactions: {
    loading: false,
    data: null,
    error: null,
  },
};
